import { About } from "./About/About";
import { Comment } from "./Comment/Comment";
import { Hero } from "./Hero/Hero";
import { OurVillas } from "./OurVillas/OurVillas";
import { Price } from "./Price/Price";
import { Program } from "./Program/Program";
import "./Retrite.css";
import { SpeakerInfo } from "./SpeakerInfo/SpeakerInfo";
import Questions from "./Questions/Questions";
import Reviews from "./Reviews/Reviews";
import HeaderR from "./HFM/Header";
import FooterR from "./HFM/Footer";
import Galarey from "./Galegey/Galarey";
import InstagramA from "./InstargamAnastasiia/InstargamA";
import Rozklad from "./Rozklad/Rozklad";

export default function Retrite() {
  return (
    <>
      <HeaderR />
      <Hero />
      <About />
      <Comment />
      <SpeakerInfo />
      <Rozklad />
      <Program />
      <OurVillas />
      <Price />
      <Questions />
      <Reviews />
      <Galarey />
      <InstagramA />
      <FooterR />
    </>
  );
}
