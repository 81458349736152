import styles from "./Modals.module.css";

import Modal from "react-modal";
import sprite from "../../icons.svg";

export const BurgerMenuModalRetrite = ({ isOpen, onClose }) => {
  return (
    <Modal
      isOpen={isOpen}
      overlayClassName={styles.BurgerMenuModalRetrite}
      className={styles.modalContentBurgerMenuR}
      closeTimeoutMS={400}
      onRequestClose={() => onClose()}
      ariaHideApp={false}
    >
      <svg
        className={styles.closeBurgerMenuBtn}
        width={32}
        height={32}
        onClick={onClose}
      >
        <use xlinkHref={`${sprite}#icon-close`}></use>
      </svg>
      <nav className={styles.burgerNavigationRetrite}>
        <ul className={styles.wrapperAnchorsRetrite}>
          <li>
            <a href="#hero">ГОЛОВНА</a>
          </li>
          <li>
            <a href="#about">про інтенсив</a>
          </li>
          <li>
            <a href="#programm">ПРОГРАМА ІНТЕНСИВУ</a>
          </li>
          <li>
            <a href="#villa">житло</a>
          </li>
          <li>
            <a href="#price">ВАРТІСТЬ</a>
          </li>
          <li>
            <a href="#question">ПИТАННЯ</a>
          </li>
          <li>
            <a href="#reviews">відгуки</a>
          </li>
          <li>
            <a href="#galarey">галерея</a>
          </li>
          <li>
            <a href="#footer">КОНТАКТИ</a>
          </li>
        </ul>
        <ul className={styles.wrapperBurgerContactRetrite}>
          <li>
            <a
              className={styles.linkToContactUsRetriteBurger}
              href="mailto:garbaruk.psyfamily@gmail.com"
            >
              garbaruk.psyfamily@gmail.com
            </a>
          </li>
          <li>
            <a
              className={styles.linkToContactUsRetriteBurger}
              href="tel:+380936576711"
            >
              +38 (093) 657 6711
            </a>
          </li>
          <li className={styles.linkToContactUsRetriteBurger}>
            Графік роботи: з 10:00 до 20:00
          </li>
        </ul>
        <ul className={styles.wrapperBorgerRetriteSocialLinks}>
          <li>
            <a
              target="_blank"
              href="https://www.instagram.com/women.psyconference/"
            >
              <svg
                className={styles.burgerRetriteSocialIcons}
                width={45}
                height={45}
              >
                <use xlinkHref={`${sprite}#icon-instagram`}></use>
              </svg>
            </a>
          </li>
          <li>
            <a target="_blank" href="https://t.me/women_psyconference">
              <svg
                className={styles.burgerRetriteSocialIcons}
                width={45}
                height={45}
              >
                <use xlinkHref={`${sprite}#icon-telegram`}></use>
              </svg>
            </a>
          </li>
        </ul>
      </nav>
    </Modal>
  );
};
