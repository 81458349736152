import styles from "./Hero.module.css";
import sprite from "../../icons.svg";
import { BookingModal } from "../BookingModal/BookingModal";

export const Hero = () => {
  return (
    <section className={styles.section} id="hero">
      <div className={styles.container}>
        <h2 className={styles.sectionTitle}>
          Тілесно-психологічний <br /> ретрит “жінки”
        </h2>
        <p className={styles.secondaryText}>
          з гештальт-терапевтом та сексологом Анастасією Матюшевською
        </p>
        <p className={styles.locationText}>
          <svg className={styles.locationIcon}>
            <use xlinkHref={`${sprite}#icon-location`}></use>
          </svg>
          Лазурове узбережжя Франції
        </p>
        <span className={styles.separationStick}></span>
        <p className={styles.dateText}>
          6-15 жовтня 2024р{" "}
          <svg className={styles.calendarIcon}>
            <use xlinkHref={`${sprite}#icon-calendar`}></use>
          </svg>
        </p>
        <p className={styles.reservationText}>
          забронюй місце зараз і потрапляй на зустріч за найнижчу ціну!
        </p>
        <BookingModal />
      </div>
    </section>
  );
};
