import style from "./Erorr.module.css";
import errorSpiralSVG from "./spiralSVG.svg";

export default function Error() {
  return (
    <section className="errorSection">
      <div className="container">
        <p className={style.oooopsText}>Oooops...</p>
        <ul className={style.wrapperErrorCode}>
          <li>4</li>
          <li>
            <img
              className={style.errorIMG}
              src={errorSpiralSVG}
              alt="spiral"
              width={350}
              height={350}
            />
          </li>
          <li>4</li>
        </ul>
        <p className={style.textErrorInfo}>
          Ця сторінка зараз недоступна, завітайте пізніше
        </p>
      </div>
    </section>
  );
}
