import style from "./Tariff.module.css";
import sprite from "../../icons.svg";
// import { Link } from 'react-router-dom'
import { ChoiseTariffModal } from "../HFM/Modals/ChoiseTariffModal";
import { useState } from "react";
import { selectIsLoggedIn } from "../../../redux/auth/selectors";
import { useSelector } from "react-redux";
import LogIn from "../../Modals/LogIn/LogIn";

export default function Tariff() {
  const [modalPrizeIsOpen, setModalPrizeOpen] = useState(false);
    const [modalLogInIsOpen, setModalLogIn] = useState(false);

    const isLoggedIn = useSelector(selectIsLoggedIn);

  return (
    <>
      <section className={style.tariffSection} id="tariffSection">
        <div className="container">
          <h1 className={style.titleTariff}>/Тарифи</h1>
          <ul className={style.wrapperTariffPlans}>
            <li>
              <p className={style.priceForTariff}>29€</p>
              <p className={style.priceForTariffS}>(≈1325₴)</p>
              <p className={style.infoForPrice}>
                <svg className={style.spiralForPriceSVG} width={27} height={27}>
                  <use xlinkHref={`${sprite}#icon-spiralSVG`}></use>
                </svg>
                Онлайн участь в одному <br /> дні конференції (на вибір)
              </p>
              <button
                className={`${style.tariffBtn} redBtn`}
                onClick={() => {
                  isLoggedIn ? setModalPrizeOpen(true) : setModalLogIn(true);
                }}
              >
                Придбати
              </button>
            </li>
            <li>
              <p className={style.priceForTariff}>39€</p>
              <p className={style.priceForTariffS}>(≈1780₴)</p>
              <p className={style.infoForPrice}>
                <svg className={style.spiralForPriceSVG} width={27} height={27}>
                  <use xlinkHref={`${sprite}#icon-spiralSVG`}></use>
                </svg>
                Онлайн участь у 2-х <br /> днях конференції
              </p>
              <p className={style.infoForPrice}>
                <svg className={style.spiralForPriceSVG} width={27} height={27}>
                  <use xlinkHref={`${sprite}#icon-spiralSVG`}></use>
                </svg>
                Доступ до презентацій спікерів
              </p>
              <button
                className={`${style.tariffBtn} redBtn`}
                onClick={() => {
                  isLoggedIn ? setModalPrizeOpen(true) : setModalLogIn(true);
                }}
              >
                Придбати
              </button>
            </li>
            <li>
              <p className={style.priceForTariff}>49€</p>
              <p className={style.priceForTariffS}>(≈2238₴)</p>
              <p className={style.infoForPrice}>
                <svg className={style.spiralForPriceSVG} width={27} height={27}>
                  <use xlinkHref={`${sprite}#icon-spiralSVG`}></use>
                </svg>
                Онлайн участь у 2-х <br /> днях конференції
              </p>
              <p className={style.infoForPrice}>
                <svg className={style.spiralForPriceSVG} width={27} height={27}>
                  <use xlinkHref={`${sprite}#icon-spiralSVG`}></use>
                </svg>
                Відеозапис двох виступів <br /> з конференції на вибір
              </p>
              <p className={style.infoForPrice}>
                <svg className={style.spiralForPriceSVG} width={27} height={27}>
                  <use xlinkHref={`${sprite}#icon-spiralSVG`}></use>
                </svg>
                Доступ до презентацій спікерів
              </p>
              <button
                className={`${style.tariffBtn} redBtn`}
                onClick={() => {
                  isLoggedIn ? setModalPrizeOpen(true) : setModalLogIn(true);
                }}
              >
                Придбати
              </button>
            </li>
            <li className={style.maximalniyTariff}>
              <p className={style.priceForTariff}>159€</p>
              <p className={style.priceForTariffS}>(≈7263₴)</p>
              <p className={style.infoForPrice}>
                <svg className={style.spiralForPriceSVG} width={27} height={27}>
                  <use xlinkHref={`${sprite}#icon-spiralSVG`}></use>
                </svg>
                Онлайн участь у 2-х <br /> днях конференції{" "}
              </p>
              <p className={style.infoForPrice}>
                <svg className={style.spiralForPriceSVG} width={27} height={27}>
                  <use xlinkHref={`${sprite}#icon-spiralSVG`}></use>
                </svg>
                Відеозапис всіх <br /> виступів конференції{" "}
              </p>
              <p className={style.infoForPrice}>
                <svg className={style.spiralForPriceSVG} width={27} height={27}>
                  <use xlinkHref={`${sprite}#icon-spiralSVG`}></use>
                </svg>
                Доступ до презентацій спікерів
              </p>
              <button
                className={`${style.tariffBtn} whiteBtn`}
                onClick={() => {
                  isLoggedIn ? setModalPrizeOpen(true) : setModalLogIn(true);
                }}
              >
                Придбати
              </button>
            </li>
          </ul>
        </div>
      </section>
      <ChoiseTariffModal
        isOpen={modalPrizeIsOpen}
        onClose={() => setModalPrizeOpen(false)}
      ></ChoiseTariffModal>
      <LogIn
        isOpen={modalLogInIsOpen}
        onClose={() => setModalLogIn(false)}
      ></LogIn>
    </>
  );
}
