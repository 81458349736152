import styles from "./ProfileModal.module.css";
import sprite from "../../icons.svg";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Modal from "react-modal";
// import MyConference from "../../UsersProfiles/MyConference/MyConference";
// import Support from "../../UsersProfiles/Support/Support";
// import Settings from "../../UsersProfiles/Settings/Settings";
// import Notification from "../../UsersProfiles/Notifications/Notifications";
// import MyProfile from "../../UsersProfiles/MyProfile/MyProfile";
// import OurConference from "../../UsersProfiles/OurConference/OurConference";
import { logOut } from "../../../redux/auth/operations";
import { useDispatch } from "react-redux";

export default function ProfileModal({ isOpen, onClose, setSelectedSection }) {

  const [localSelectedSection, setLocalSelectedSection] = useState("Profile");
  const dispatch = useDispatch();

  // Обновляем родительскую секцию при закрытии модального окна
 useEffect(() => {
   if (!isOpen && typeof setSelectedSection === "function") {
     setSelectedSection(localSelectedSection);
   }
 }, [isOpen, localSelectedSection, setSelectedSection]);

  const handleSectionChange = (section) => {
    setLocalSelectedSection(section); // Меняем локальную секцию
    onClose(); // Закрываем модальное окно
  };

  return (
    <Modal
      isOpen={isOpen}
      overlayClassName={styles.modalProfile}
      className={styles.modalContentProfile}
      closeTimeoutMS={400}
      onRequestClose={onClose}
      ariaHideApp={false}
    >
      <ul className={styles.wrapperProfileNavigation}>
        <li
          className={`${styles.navigation} ${
            localSelectedSection === "Profile" ? styles.navigationActive : ""
          }`}
          onClick={() => handleSectionChange("Profile")}
        >
          <Link to="/profile">
            <svg className={styles.navigationSVG} width={24} height={24}>
              <use xlinkHref={`${sprite}#icon-user`}></use>
            </svg>
            Мій профіль
          </Link>
        </li>
        <li
          className={`${styles.navigation} ${
            localSelectedSection === "MyConference"
              ? styles.navigationActive
              : ""
          }`}
          onClick={() => handleSectionChange("MyConference")}
        >
          <Link to="/profile">
            <svg className={styles.navigationSVG} width={24} height={24}>
              <use xlinkHref={`${sprite}#icon-playProfile`}></use>
            </svg>
            Мої записи
          </Link>
        </li>
        <li
          className={`${styles.navigation} ${
            localSelectedSection === "OurConference"
              ? styles.navigationActive
              : ""
          }`}
          onClick={() => handleSectionChange("OurConference")}
        >
          <Link to="/profile">
            <svg className={styles.navigationSVG} width={24} height={24}>
              <use xlinkHref={`${sprite}#icon-client`}></use>
            </svg>
            Наші конференції
          </Link>
        </li>
        <li
          className={`${styles.navigation} ${
            localSelectedSection === "Notification"
              ? styles.navigationActive
              : ""
          }`}
          onClick={() => handleSectionChange("Notification")}
        >
          <Link to="/profile">
            <svg className={styles.navigationSVG} width={24} height={24}>
              <use xlinkHref={`${sprite}#icon-notificationsProfile`}></use>
            </svg>
            Сповіщення
          </Link>
        </li>
        <li
          className={`${styles.navigation} ${
            localSelectedSection === "Settings" ? styles.navigationActive : ""
          }`}
          onClick={() => handleSectionChange("Settings")}
        >
          <Link to="/profile">
            <svg className={styles.navigationSVG} width={24} height={24}>
              <use xlinkHref={`${sprite}#icon-settingsProfile`}></use>
            </svg>
            Налаштування
          </Link>
        </li>
        <li
          className={`${styles.navigation} ${
            localSelectedSection === "Support" ? styles.navigationActive : ""
          }`}
          onClick={() => handleSectionChange("Support")}
        >
          <Link to="/profile">
            <svg className={styles.navigationSVG} width={24} height={24}>
              <use xlinkHref={`${sprite}#icon-supportProfile`}></use>
            </svg>
            Підтримка
          </Link>
        </li>
      </ul>
      <Link to="/conference" className={styles.logOutBtn}>
        <button
          className={styles.logOutBtn}
          onClick={() => {
            dispatch(logOut());
            onClose();
          }}
        >
          <svg className={styles.logOutSVG} width={24} height={24}>
            <use xlinkHref={`${sprite}#icon-logOutSVG`}></use>
          </svg>
          Вийти
        </button>
      </Link>
    </Modal>
  );
}
