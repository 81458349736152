import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  MoreQuestions,
  JoinZoomModal,
  JoinZoomModalAlternative,
} from "../HFM/Modals/Modules";
import { ChoiseTariffModal } from "../HFM/Modals/ChoiseTariffModal";
import sprite from "../../icons.svg";
import style from "./Hero.module.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Pagination } from "swiper/modules";
import "swiper/css/pagination";
import About from "../About/About";
import { selectIsLoggedIn } from "../../../redux/auth/selectors";
import { useSelector } from "react-redux";
import LogIn from "../../Modals/LogIn/LogIn";
// import { Banner } from "../Banner/Banner";

export default function Hero() {
  const [prizeModalOpen, setPrizeModalOpen] = useState(false);
  const [tariffModalOpen, setTariffModalOpen] = useState(false);
  const [joinZoomModalOpen, setjoinZoomModalOpen] = useState(false);
  const [joinZoomModalAlternativeOpen, setjoinZoomModalAlternativeOpen] =
    useState(false);
  const [setUserDataModalOpen] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [modalLogInIsOpen, setModalLogIn] = useState(false);

  const isLoggedIn = useSelector(selectIsLoggedIn);

  const openModal = (modalName) => {
    // console.log(`Opening modal: ${modalName}`);
    if (modalName === "prizeModal") {
      setPrizeModalOpen(true);
    } else if (modalName === "tariffModal") {
      setTariffModalOpen(true);
    } else if (modalName === "userDataModal") {
      setUserDataModalOpen(true);
    } else if (modalName === "joinZoomModal") {
      setjoinZoomModalOpen(true);
    } else if (modalName === "joinZoomModalAlternative") {
      setjoinZoomModalAlternativeOpen(true);
    }
  };

  const closeModal = (modalName) => {
    // console.log(`Closing modal: ${modalName}`);
    if (modalName === "prizeModal") {
      setPrizeModalOpen(false);
    } else if (modalName === "tariffModal") {
      setTariffModalOpen(false);
    } else if (modalName === "userDataModal") {
      setUserDataModalOpen(false);
    } else if (modalName === "joinZoomModal") {
      setjoinZoomModalOpen(false);
    } else if (modalName === "joinZoomModalAlternative") {
      setjoinZoomModalAlternativeOpen(false);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoaded(true);
    }, 300);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <section className={style.heroSection} id="heroSection">
        <div className="container">
          {/* <Banner
            openModal={openModal}
            conferenceDate={"2024-10-27T08:00:00Z"}
          /> */}
          <article
            className={`${style.wrapperBanner} ${loaded ? style.loaded : ""}`}
          >
            <ul>
              <li>
                <p
                  className={`${style.heroBannerInfoText} ${style.heroBannerInfoTextF}`}
                >
                  online
                </p>
              </li>
              <li className={style.wrapperBannerInformation}>
                <svg
                  className={style.iconWomenPlaceBanner}
                  width={205}
                  height={197}
                >
                  <use xlinkHref={`${sprite}#redWomenRestrictions`}></use>
                </svg>

                <p className={style.heroBannerSecondInfoText}>
                  четвертий з 11 етапів психоемоційного розвитку
                </p>
                <div className={style.wrapperPresentationTopic}>
                  <h2>
                    Турбота та здатність турбуватись <br />
                    <span className={style.mainTitleSpan}>{/*  */}</span>
                  </h2>
                </div>
                <p className={style.textAboutWomen}>
                  до жінок, біля жінок, жінками
                </p>
                <button
                  className={style.whiteBtnHero}
                  onClick={() => {
                    isLoggedIn ? openModal("tariffModal") : setModalLogIn(true);
                  }}
                >
                  Відвідати конференцію
                </button>
                <Link to="https://forms.gle/5VqRCEDD1VH2ZRpK6" target="_blank">
                  <button className={style.joinHowSpeakerBtn}>
                    Стати Спікером
                  </button>
                </Link>
              </li>
              <li>
                <p
                  className={`${style.heroBannerInfoText} ${style.heroBannerInfoTextS}`}
                >
                  20-21.01
                </p>
              </li>
            </ul>
            <p className={style.womenTextOnBanner1}>жінки</p>
            <p className={style.womenTextOnBanner2}>жінки</p>
            <p className={style.womenTextOnBanner3}>жінки</p>
            <p className={style.womenTextOnBanner4}>жінки</p>
            <p className={style.womenTextOnBanner5}>жінки</p>
            <p className={style.womenTextOnBanner6}>жінки</p>
            <p className={style.womenTextOnBanner7}>жінки</p>
            <p className={style.womenTextOnBanner8}>жінки</p>
            <p className={style.womenTextOnBanner9}>жінки</p>
            <p className={style.womenTextOnBanner10}>жінки</p>
            <p className={style.womenTextOnBanner11}>жінки</p>
            <p className={style.womenTextOnBanner12}>жінки</p>
            <p className={style.womenTextOnBanner13}>жінки</p>
            <p className={style.womenTextOnBanner14}>жінки</p>
          </article>
          <About />
          <div
            className={`${style.wrapperHeroInformation} ${
              loaded ? style.loaded : ""
            }`}
          >
            <article className={style.wrapperNextConferenceInformation}>
              <p className={style.numberOfConference}>
                четвертий з 11 етапів психоемоційного розвитку
              </p>
              <h2 className={style.titleFirstConference}>
                Турбота та здатність турбуватись <br />
                <br />
                <span className={style.mainTitleSpanS}>{/*  */}</span>
              </h2>
              <h3 className={style.titleFirstConferenceDop}>
                до жінок, біля жінок, жінками
              </h3>
              <p className={style.dateInformation}>
                20-21 ciчня 2025 <span></span> платформа зум
              </p>
              <p className={style.dateInforationMore}>
                з 10:00 до 19:30 за київським часом - перший день <br />
                з 10:00 до 20:15 за київським часом - другий день <br />
                <span>обідня перерва з 14:30 до 15:30</span>
              </p>
              <div className={style.wrapperButtonFirstConference}>
                <button
                  className="redBtn"
                  onClick={() => {
                    isLoggedIn ? openModal("tariffModal") : setModalLogIn(true);
                  }}
                >
                  Відвідати конференцію
                </button>
                <button
                  className="transperentBtn"
                  onClick={() => openModal("prizeModal")}
                >
                  Додаткові питання
                </button>
              </div>
            </article>
            <svg className={style.iconRedWomenSVG} width={569} height={546}>
              <use xlinkHref={`${sprite}#redWomenRestrictions`}></use>
            </svg>
          </div>
          <div className={style.wrapperInfoAboutConference}>
            <ul>
              <li>
                <p className={style.textInfoAboutConferene}>
                  <span> Пам’ятаєте, що таке сценарні рішення?</span> Це ті
                  установки, які ми приймаємо ще в дитинстві, формуючи уявлення
                  про те, як поводитися, думати і ким стати в цьому світі. Однак
                  з часом вони перестають працювати, стаючи обмеженнями, що не
                  дозволяють жити повним життям.
                </p>
                <p className={style.textInfoAboutConferene}>
                  <span> Але чи все так однозначно?</span>
                </p>
                <p className={style.aboutFreePlace}>Залишилось 240 місць</p>
              </li>
              <li>
                <p className={style.textInfoAboutConferene}>
                  На конференції ми <span>глибше зануримось</span> у тему
                  кордонів: як вони формуються та змінюються протягом життя.
                </p>
                <p className={style.textInfoAboutConferene}>
                  <span>Поговоримо</span> про істинні та хибні цінності,
                  досліджуючи, як вони впливають на наше самовідчуття та
                  стосунки.
                </p>
                <p className={style.textInfoAboutConferene}>
                  <span>Розглянемо,</span> коли і за яких обставин можна
                  порушувати свої найважливіші кордони, і що це означає
                </p>
                <p className={style.textInfoAboutConferene}>
                  <span>Ми дослідимо,</span> хто така жінка з кордонами: як вона
                  будує свою взаємодію з собою та суспільством, як вміло
                  управляє своїми межами у різних ситуаціях.
                </p>
              </li>
              <li>
                <p className={style.textInfoAboutConferene}>
                  Також <span>розглянемо,</span> як порушення кордонів може
                  сприяти особистісному зростанню та дорослішанню, а де межі
                  дозволеного для жінки в сучасному світі.
                </p>
                <p className={style.textInfoAboutConferene}>
                  Ця конференція стане простором для глибокого усвідомлення
                  власних заборон і правил. <span>Ми дослідимо, </span>
                  як ці заборони можуть стати рушієм вашого розвитку і допомогти
                  вам жити більш щасливим та автентичним життям.
                </p>
                <p className={style.textInfoAboutConferene}>
                  <span>
                    Долучайтеся до нас і ми проведемо вас по всім етапам
                    розвитку ЖІНКИ!
                  </span>
                </p>
              </li>
            </ul>
            <p className={style.aboutFreePlace}>Залишилось 240 місць</p>
          </div>
          <Swiper
            slidesPerView={1}
            spaceBetween={40}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination]}
            autoHeight={true}
            className={style.mySwiperHeroInfo}
          >
            <SwiperSlide>
              <p className={style.textInfoAboutConferene}>
                <span>Турбота та здатність турбуватись</span>
              </p>
              <p className={style.textInfoAboutConferene}>
                <span> Пам’ятаєте, що таке сценарні рішення?</span> Це ті
                установки, які ми приймаємо ще в дитинстві, формуючи уявлення
                про те, як поводитися, думати і ким стати в цьому світі. Однак з
                часом вони перестають працювати, стаючи обмеженнями, що не
                дозволяють жити повним життям.
              </p>
              <p className={style.textInfoAboutConferene}>
                <span> Але чи все так однозначно?</span>
              </p>
              <p className={style.aboutFreePlace}>Залишилось 240 місць</p>
            </SwiperSlide>
            <SwiperSlide>
              <p className={style.textInfoAboutConferene}>
                На конференції ми <span>глибше зануримось</span> у тему
                кордонів: як вони формуються та змінюються протягом життя.{" "}
                <br />
                <span>Поговоримо</span> про істинні та хибні цінності,
                досліджуючи, як вони впливають на наше самовідчуття та стосунки.
              </p>

              <p className={style.textInfoAboutConferene}>
                <span>Розглянемо,</span> коли і за яких обставин можна
                порушувати свої найважливіші кордони, і що це означає
              </p>
              <p className={style.aboutFreePlace}>Залишилось 240 місць</p>
            </SwiperSlide>
            <SwiperSlide>
              <p className={style.textInfoAboutConferene}>
                <span>Ми дослідимо,</span> хто така жінка з кордонами: як вона
                будує свою взаємодію з собою та суспільством, як вміло управляє
                своїми межами у різних ситуаціях.
              </p>
              <p className={style.textInfoAboutConferene}>
                Також <span>розглянемо,</span> як порушення кордонів може
                сприяти особистісному зростанню та дорослішанню, а де межі
                дозволеного для жінки в сучасному світі.
              </p>
              <p className={style.aboutFreePlace}>Залишилось 240 місць</p>
            </SwiperSlide>
            <SwiperSlide>
              <p className={style.textInfoAboutConferene}>
                Ця конференція стане простором для глибокого усвідомлення
                власних заборон і правил. <br /> <span>Ми дослідимо, </span>
                як ці заборони можуть стати рушієм вашого розвитку і допомогти
                вам жити більш щасливим та автентичним життям.
              </p>

              <p className={style.aboutFreePlace}>Залишилось 240 місць</p>
            </SwiperSlide>
            <SwiperSlide>
              <p className={style.textInfoAboutConferene}>
                <span>
                  Долучайтеся до нас і ми проведемо вас по всім етапам розвитку
                  ЖІНКИ!
                </span>
              </p>
            </SwiperSlide>
          </Swiper>
        </div>
      </section>

      <MoreQuestions
        isOpen={prizeModalOpen}
        onClose={() => closeModal("prizeModal")}
      ></MoreQuestions>
      <ChoiseTariffModal
        isOpen={tariffModalOpen}
        onClose={() => closeModal("tariffModal")}
      ></ChoiseTariffModal>

      <JoinZoomModal
        isOpen={joinZoomModalOpen}
        onClose={() => closeModal("joinZoomModal")}
        openModal={openModal}
      />
      <LogIn
        isOpen={modalLogInIsOpen}
        onClose={() => setModalLogIn(false)}
      ></LogIn>
      <JoinZoomModalAlternative
        isOpen={joinZoomModalAlternativeOpen}
        onClose={() => closeModal("joinZoomModalAlternative")}
        openModal={openModal}
      />
    </>
  );
}
