import style from "./AggreSpeakers.module.css";
import sprite from "../../../icons.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";

import VatrichIMGA from "./VatrichIMGA.webp";
import NazarenkoIMGA from "./NazarenkoIMGA.webp";
import DiveevaIMGA from "./DiveevaIMGA.webp";
import MatushevskaIMGA from "./MatushevskaIMGA.webp";
import IshkovaIMGA from "./IshkovaIMGA.webp";
import PrudnikovaIMGA from "./PrudnikovaIMGA.webp";
import MarchekIMGA from "./MarchekIMGA.webp";
import AdamovaIMGA from "./AdamovaIMGA.webp";
import PavluchokIMGA from "./PavluchokIMGA.webp";
import LevchenkoIMGA from "./LevchenkoIMGA.webp";
import ZimovinIMGA from "./ZimovinIMGA.webp";
import BaevaIMGA from "./BaevaIMGA.webp";

export default function AggreSpeakers() {
  const aggreSpeakersJSON = [
    {
      speakersIMG: NazarenkoIMGA,
      speakersName: "Тетяна Назаренко",
      speakersTema:
        "Аутоагресія при складних стосунках з їжею та невдоволенні тілом",
      speakersAbout: "клінічний психолог, супервізор",
      width: "276",
      height: "316",
    },
    {
      speakersIMG: DiveevaIMGA,
      speakersName: "Ольга Дівєєва",
      speakersTema: "Соматизація жіночого здоровʼя: де живе агресія",
      speakersAbout: "Клінічний психолог Експерт з психосоматики",
      width: "250",
      height: "316",
    },
    {
      speakersIMG: MatushevskaIMGA,
      speakersName: "Анастасія-Олімпія Матюшевська",
      speakersTema: "«Агресія, як провідний інструмент у розвитку жінки»",
      speakersAbout: "Гештальт-терапевт, сексолог",
      width: "306",
      height: "366",
    },
    {
      speakersIMG: IshkovaIMGA,
      speakersName: "Наталія Ішкова",
      speakersTema: "Жінка, як держава на карті людства",
      speakersAbout:
        "психотерапевт у транзактному аналізі, гештальті та сексології",
      width: "290",
      height: "360",
    },
    {
      speakersIMG: PrudnikovaIMGA,
      speakersName: "Вікторія Пруднікова",
      speakersTema:
        "Аудити життя (інтерактив з виявлення підсвідомих причин агресивних патернів)",
      speakersAbout: "експерт з ментальної генетики, консультант",
      width: "313",
      height: "364",
    },
    {
      speakersIMG: MarchekIMGA,
      speakersName: "Світлана Марчек",
      speakersTema: "Бити чи не бити? Що впливає на вибір?",
      speakersAbout: "гештальт-терапевт, травматерапевт, арт-терапевт",
      width: "367",
      height: "359",
    },
    {
      speakersIMG: AdamovaIMGA,
      speakersName: "Лена Адамова",
      speakersTema: "Руйнівні та пригнічені способи жити своє життя",
      speakersAbout: "психолог, психосоматолог, коуч-трансформатор",
      width: "335",
      height: "353",
    },
    {
      speakersIMG: PavluchokIMGA,
      speakersName: "Наталія Павлючок",
      speakersTema: "Жінка лідерка та агресія",
      speakersAbout: "підприємниця, амбасадорка здорових сімейних відносин",
      width: "323",
      height: "351",
    },
    {
      speakersIMG: LevchenkoIMGA,
      speakersName: "Василіса Левченко",
      speakersTema:
        "Життя сильної жінки в світі стереотипів про «слабку стать»",
      speakersAbout: "психотерапевт, військовий волонтер",
      width: "360",
      height: "372",
    },
    {
      speakersIMG: ZimovinIMGA,
      speakersName: "Олексій Зимовін",
      speakersTema: "Чоловік у полі жіночої агресії",
      speakersAbout: "гештальт-терапевт, психолог, PhD",
      width: "314",
      height: "338",
    },
    {
      speakersIMG: BaevaIMGA,
      speakersName: "Олена Баєва",
      speakersTema: "Жіноча агресія: сили творення та руйнування",
      speakersAbout: "гештальт-терапевт, супервізор, психолог, педагог",
      width: "286",
      height: "374",
    },
    {
      speakersIMG: VatrichIMGA,
      speakersName: "Ірина Ватрич",
      speakersTema: "Астрологічні показники агресії",
      speakersAbout: "астролог",
      width: "304",
      height: "323",
    },
  ];
  return (
    <section className={style.aggreSpeakersSection} id="speakersSection">
      <div className="container">
        <h2 className={style.titleAggreSpeakers}>/спікери конференції</h2>
        <ul className={style.wrapperAggreSpeakersInfo}>
          {aggreSpeakersJSON.map((speakersCard) => {
            return (
              <li
                className={style.aggreSpeakerCard}
                key={speakersCard.speakersName}
              >
                <img
                  className={style.aggrespeakerIMG}
                  width={speakersCard.width}
                  height={speakersCard.height}
                  src={speakersCard.speakersIMG}
                  alt={speakersCard.speakersName}
                />
                <p className={style.aggreSpeakerNameOnCard}>
                  {speakersCard.speakersName}
                </p>
                <h3 className={style.aggreTitleSpeakerTema}>тема виступу:</h3>
                <p className={style.agreeTextSpeakerTema}>
                  {speakersCard.speakersTema}
                </p>
                <h4 className={style.aggreTitleSpeakerTema}>про спікера:</h4>
                <p className={style.agreeTextSpeakerAbout}>
                  {speakersCard.speakersAbout}
                </p>
                <span className={style.aggreEllipseUnderSpeakerIMG}></span>
              </li>
            );
          })}
        </ul>

        <div className={style.mySwiperAggrePageSpeakersNavigation}>
          <div className={style.swiperButtonPrevAggre}>
            <svg
              onClick={(e) => e.preventDefault()}
              className=""
              width={60}
              height={60}
              fill="white"
            >
              <use xlinkHref={`${sprite}#arrow-right`}></use>
            </svg>
          </div>
          <div className={style.swiperButtonNextAggre}>
            <svg
              onClick={(e) => e.preventDefault()}
              className=""
              width={60}
              height={60}
              fill="white"
            >
              <use xlinkHref={`${sprite}#arrow-left`}></use>
            </svg>
          </div>
        </div>
        <Swiper
          slidesPerView={1}
          spaceBetween={40}
          pagination={{
            type: "fraction",
          }}
          navigation={{
            prevEl: `.${style.swiperButtonPrevAggre}`,
            nextEl: `.${style.swiperButtonNextAggre}`,
          }}
          modules={[Pagination, Navigation]}
          autoHeight={true}
          className={`${style.mySwiperAggrePageSpeakers} ${style.reset}`}
        >
          {aggreSpeakersJSON.map((speakersCard) => {
            return (
              <SwiperSlide
                key={speakersCard.speakersName}
                className={style.swiperSlide}
              >
                <img
                  className={style.aggrespeakerIMG}
                  width={speakersCard.width}
                  height={speakersCard.height}
                  src={speakersCard.speakersIMG}
                  alt={speakersCard.speakersName}
                />
                <p className={style.aggreSpeakerNameOnCard}>
                  {speakersCard.speakersName}
                </p>
                <h3 className={style.aggreTitleSpeakerTema}>тема виступу:</h3>
                <p className={style.agreeTextSpeakerTema}>
                  {speakersCard.speakersTema}
                </p>
                <h4 className={style.aggreTitleSpeakerTema}>про спікера:</h4>
                <p className={style.agreeTextSpeakerAbout}>
                  {speakersCard.speakersAbout}
                </p>
                <span className={style.aggreEllipseUnderSpeakerIMG}></span>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </section>
  );
}
