import styles from "./Price.module.css";
import sprite from "../../icons.svg";
import { BookingModal } from "../BookingModal/BookingModal";

export const Price = () => {
  return (
    <section className={styles.section} id="price">
      <div className={styles.container}>
        <h2 className={styles.sectionTitle}>вартість</h2>
        <div className={styles.cardsContainer}>
          <div className={styles.card}>
            <p className={styles.cardTitle}>До 01.09.24</p>
            <div className={styles.cardDescriptionContainer}>
              <svg
                className={styles.cardDescriptionIcon}
                width={620}
                height={420}
              >
                <use xlinkHref={`${sprite}#icon-spiralRetrite`}></use>
              </svg>
              <p>
                2000€ - участь та розважальна програма <br /> 700€ - проживання
                у двомісному номері готелю або вілли <br />
                <span className={styles.italicText}>
                  *тариф діє при умові повної оплати до вказаної дати
                </span>
              </p>
            </div>
            <p className={styles.cardPrice}>2700€</p>
          </div>
          <div className={`${styles.card} ${styles.whiteCard}`}>
            <p className={styles.cardTitle}>До 06.09.24</p>
            <div className={styles.cardDescriptionContainer}>
              <svg
                className={styles.cardDescriptionIcon}
                width={620}
                height={420}
              >
                <use xlinkHref={`${sprite}#icon-spiralRetrite`}></use>
              </svg>

              <p>
                2300€ - участь та розважальна програма <br /> 700€ - проживання
                у двомісному номері готелю або вілли <br />
                <span className={styles.italicText}>
                  *покрокова оплата, яка здійснюється кожного 1-го числа місяця
                  у розмірі 1000€
                </span>
              </p>
            </div>
            <p className={styles.cardPrice}>3000€</p>
          </div>
        </div>
        <BookingModal />
      </div>
    </section>
  );
};
