import sprite from "../../icons.svg";
import styles from "./Footer.module.css";

export default function FooterR() {
  return (
    <footer className={styles.FooterR} id="footer">
      <svg className={styles.mainLogoIMGRetrite} width={247} height={66}>
        <use xlinkHref={`${sprite}#icon-womenPlace`}></use>
      </svg>
      <nav className={styles.navigationRetriteFooter}>
        <ul className={styles.wrapperAnchorsRetriteFooter}>
          <li>
            <a href="#hero">Головна</a>
          </li>
          <li>
            <a href="#about">Про інтенсив</a>
          </li>
          <li>
            <a href="#programm">Програма інтенсиву</a>
          </li>
          <li>
            <a href="#price">Вартість</a>
          </li>
          <li>
            <a href="#question">Питання</a>
          </li>
          <li>
            <a href="#reviews">Відгуки</a>
          </li>
          <li>
            <a href="#galarey">Галерея</a>
          </li>
          <li>
            <a href="#footer">Контакти</a>
          </li>
        </ul>
        <ul className={styles.wrapperBurgerContactRetriteFooter}>
          <li>
            <a
              className={styles.linkToContactUsRetriteBurgerFooter}
              href="mailto:women@psyconference.org"
            >
              women@psyconference.org
            </a>
          </li>
          <li>
            <a
              className={styles.linkToContactUsRetriteBurgerFooter}
              href="tel:+380936576711"
            >
              +38 (093) 657 6711
            </a>
          </li>
          <li className={styles.linkToContactUsRetriteBurgerFooter}>
            Графік роботи: з 10:00 до 20:00
          </li>
        </ul>
        <ul className={styles.wrapperBorgerRetriteSocialLinksFooter}>
          <li>
            <a
              target="_blank"
              href="https://www.instagram.com/women.psyconference/"
            >
              <svg
                className={styles.burgerRetriteSocialIconsFooter}
                width={45}
                height={45}
              >
                <use xlinkHref={`${sprite}#icon-instagram`}></use>
              </svg>
            </a>
          </li>
          <li>
            <a target="_blank" href="https://t.me/women_psyconference">
              <svg
                className={styles.burgerRetriteSocialIconsFooter}
                width={45}
                height={45}
              >
                <use xlinkHref={`${sprite}#icon-telegram`}></use>
              </svg>
            </a>
          </li>
        </ul>
      </nav>
    </footer>
  );
}
