import sprite from "../../../icons.svg";
import { useState, useEffect } from "react";
import axios from "axios";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { selectIsLoggedIn, selectUser } from "../../../../redux/auth/selectors";
import { register } from "../../../../redux/auth/operations";

// eslint-disable-next-line react/prop-types
export const ChoiseTariffModal = ({ isOpen, onClose }) => {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const userData = useSelector(selectUser);

  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    firstName: "",
    email: "",
    additionalInfo: "",
    promoCode: "",
  });

  // Если человек залогинен его данные вписываются автоматически
  useEffect(() => {
    if (isLoggedIn && userData) {
      setFormData({
        firstName: userData.firstName || "",
        lastName: userData.lastName || "",
        email: userData.email || "",
      });
    }
  }, [isLoggedIn, userData]);

  const promocodesArray = [];

  const [selectedTariffs, setSelectedTariffs] = useState([]);
  const [usersPromo, setUsersPromo] = useState("");
  const [isPromoValid, setIsPromoValid] = useState(false);
  const [oldAmount, setOldAmount] = useState(0);
  const [amount, setAmount] = useState(0);
  const [isAgreementChecked, setIsAgreementChecked] = useState(false);

  const handleAgreementChange = (event) => {
    setIsAgreementChecked(event.target.checked); // Обновляем состояние при изменении чекбокса
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps

  useEffect(() => {
    const tariffPrices = {
      parmsZero: 29,
      parmsOne: 39,
      parmsTwo: 49,
      parmsThree: 159,
      parmsFour: 999,
      parmsFive: 1699,
    };

    let totalPrice = 0;
    selectedTariffs.forEach((tariffId) => {
      totalPrice += tariffPrices[tariffId];
    });
    setAmount(totalPrice);
  }, [selectedTariffs]);

  // useEffect(() => {
  //   console.log("Updated amount: ", amount);
  // }, [amount]);

  const calculatePrice = () => {
    const tariffPrices = {
      parmsZero: 29,
      parmsOne: 39,
      parmsTwo: 49,
      parmsThree: 159,
      parmsFour: 999,
      parmsFive: 1699,
    };

    let totalPrice = 0;
    selectedTariffs.forEach((tariffId) => {
      totalPrice += tariffPrices[tariffId];
    });
    setAmount(totalPrice);
    setOldAmount(0);
    return totalPrice;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    console.log(formData);
  };

  const handleChangePromo = async (e) => {
    const { value } = e.target;

    const usersPromo = value.toLowerCase();
    setUsersPromo(usersPromo);
    calculatePrice();
    setIsPromoValid(false);
    setFormData((prevData) => ({
      ...prevData,
      promoCode: value,
    }));

    const isPromoValid =
      promocodesArray.find((promoCode) => promoCode === usersPromo.trim()) &&
      usersPromo.trim() !== "";

    const isPromoBorders = usersPromo.trim() === "кордони";

    const isTariffEqualParmsOne =
      selectedTariffs.includes("parmsOne") && selectedTariffs.length === 1;

    const isTariffEqualParmsTwo =
      selectedTariffs.includes("parmsTwo") && selectedTariffs.length === 1;

    if (isPromoValid && isPromoBorders) {
      const totalAmount = calculatePrice();
      const newAmount = Math.floor((totalAmount / 100) * 90);
      console.log(newAmount);
      setIsPromoValid(true);
      setOldAmount(totalAmount);
      setAmount(newAmount);
      return;
    }

    if (isPromoValid && isTariffEqualParmsOne) {
      const totalAmount = calculatePrice();
      const newAmount = Math.floor((totalAmount / 100) * 95);
      console.log(newAmount);
      setIsPromoValid(true);
      setOldAmount(totalAmount);
      setAmount(newAmount);
      return;
    }
    if (isPromoValid && isTariffEqualParmsTwo) {
      const totalAmount = calculatePrice();
      const newAmount = Math.floor((totalAmount / 100) * 90);
      console.log(newAmount);
      setIsPromoValid(true);
      setOldAmount(totalAmount);
      setAmount(newAmount);
      return;
    }
  };

  const handleTariffSelection = (event) => {
    const tariffId = event.target.id;
    setOldAmount(0);
    setIsPromoValid(false);
    setUsersPromo("");
    if (event.target.checked) {
      setSelectedTariffs([...selectedTariffs, tariffId]);
    } else {
      setSelectedTariffs(
        selectedTariffs.filter((tariff) => tariff !== tariffId)
      );
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !formData.firstName ||
      !formData.lastName ||
      !formData.email ||
      !formData.additionalInfo
    ) {
      alert(
        "Будь ласка, заповніть обов'язкові поля: Ім'я, Прізвище, Email і Додаткова інформація."
      );
      return;
    }

    if (selectedTariffs.length === 0) {
      alert("Будь ласка, оберіть хоча б один тариф.");
      return;
    }

    if (typeof fbq !== "undefined") {
      // eslint-disable-next-line no-undef
      fbq("track", "InitiateCheckout");
    }

    const user = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      additionalInfo: formData.additionalInfo,
      password: formData?.password,
    };

    const userData = {
      selectedTariffs,
      video: [],
    };

    try {
      if (!isLoggedIn) {
        await dispatch(register(user));
      }
      const conferenceName = "careConference";
      // CHANGE TO WOMENPLACE
      const response = await axios.post("https://women.place/create-payment", {
        amount: amount * 100,
        currency: 978,
        redirectUrl: "https://women.place/thxforpayment",
        conferenceName,
        selectedTariffs: userData.selectedTariffs,
        video: userData.video,
        email: user.email,
        promoCode: formData.promoCode,
      });
      window.location.href = response.data.pageUrl;
    } catch (error) {
      console.error(
        "Помилка під час створення платежу:",
        error.response ? error.response.data : error.message
      );
      alert(
        `Помилка під час створення платежу: ${
          error.response ? JSON.stringify(error.response.data) : error.message
        }`
      );
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      overlayClassName={"modalChoiseTariff"}
      className={"modalContenlChoiseTariff"}
      closeTimeoutMS={400}
      onRequestClose={onClose}
      ariaHideApp={false}
    >
      <svg
        className="closeModalQuestions"
        width={32}
        height={32}
        onClick={onClose}
      >
        <use xlinkHref={`${sprite}#icon-close`}></use>
      </svg>
      <h2 className="titleModalTariffPlan">оберіть тариф/ абонемент:</h2>
      <form className="mainForm">
        <div className="wrapperTariffCheck">
          <label htmlFor="parmsZero" className="labelCheckBoxVisitConference">
            Онлайн участь в одному дні конференції (на вибір)
            <input
              id="parmsZero"
              type="checkbox"
              className="custom-checkbox"
              onChange={handleTariffSelection}
            />
            <div className="checkbox__checkmark"></div>
            <div className="checkbox__body"></div>
          </label>
        </div>
        <div className="wrapperTariffCheck">
          <label htmlFor="parmsOne" className="labelCheckBoxVisitConference">
            Онлайн участь у 2-х днях конференції
            <input
              id="parmsOne"
              type="checkbox"
              className="custom-checkbox"
              onChange={handleTariffSelection}
            />
            <div className="checkbox__checkmark"></div>
            <div className="checkbox__body"></div>
          </label>
        </div>
        <div className="wrapperTariffCheck">
          <label htmlFor="parmsTwo" className="labelCheckBoxVisitConference">
            Онлайн участь у 2-х днях конференції <br />
            Відеозапис двох виступів з конференції на вибір
            <input
              id="parmsTwo"
              type="checkbox"
              className="custom-checkbox"
              onChange={handleTariffSelection}
            />
            <div className="checkbox__checkmark"></div>
            <div className="checkbox__body"></div>
          </label>
        </div>
        <div className="wrapperTariffCheck">
          <label htmlFor="parmsThree" className="labelCheckBoxVisitConference">
            Онлайн участь у 2-х днях конференції <br /> Відеозапис всіх виступів
            конференції
            <input
              id="parmsThree"
              type="checkbox"
              className="custom-checkbox"
              onChange={handleTariffSelection}
            />
            <div className="checkbox__checkmark"></div>
            <div className="checkbox__body"></div>
          </label>
        </div>
        <div className="wrapperTariffCheck">
          <label htmlFor="parmsFour" className="labelCheckBoxVisitConference">
            Онлайн участь на 11 конференціях <br /> Відеозаписи всіх виступів з
            11 конференцій
            <input
              id="parmsFour"
              type="checkbox"
              className="custom-checkbox"
              onChange={handleTariffSelection}
            />
            <div className="checkbox__checkmark"></div>
            <div className="checkbox__body"></div>
          </label>
        </div>
        <div className="wrapperTariffCheck">
          <label htmlFor="parmsFive" className="labelCheckBoxVisitConference">
            Онлайн участь на 11 конференціях <br />
            Відеозаписи всіх виступів з 11 конференцій <br />
            1 консултація на вибір одного зі спікерів після кожної конференції
            <input
              id="parmsFive"
              type="checkbox"
              className="custom-checkbox"
              onChange={handleTariffSelection}
            />
            <div className="checkbox__checkmark"></div>
            <div className="checkbox__body"></div>
          </label>
        </div>
      </form>
      <p className="textFormPriceForTariff">
        Вартість{" "}
        <span className="priceSpan">
          {amount}{" "}
          {oldAmount > 0 && (
            <span className="oldPriceSpan">{oldAmount} EUR</span>
          )}
        </span>{" "}
        EUR
      </p>
      <h2 className="titleGetUserData">2. надайте ваші данні:</h2>
      <form className="formForUserData" id="personForm" onSubmit={handleSubmit}>
        <div className="form-group">
          <label className="labelForUserData" htmlFor="firstName">
            <input
              className="inputUserDate"
              type="text"
              id="firstName"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              required
              disabled={isLoggedIn}
            />
            <span className="spanInputPlaceholder">Імʼя</span>
          </label>
        </div>
        <div className="form-group">
          <label className="labelForUserData" htmlFor="lastName">
            <input
              className="inputUserDate"
              type="text"
              id="lastName"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              disabled={isLoggedIn}
            />
            <span className="spanInputPlaceholder">Прізвище</span>
          </label>
        </div>
        <div className="form-group">
          <label className="labelForUserData" htmlFor="email">
            <input
              className="inputUserDate"
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              disabled={isLoggedIn}
            />
            <span className="spanInputPlaceholder">Email</span>
          </label>
        </div>
        {!isLoggedIn && (
          <div className={isLoggedIn ? "form-groupHide" : "form-group"}>
            <label className="labelForUserData" htmlFor="password">
              <input
                className="inputUserDate"
                type="password"
                id="password"
                name="password"
                onChange={handleChange}
                required
              />
              <span className="spanInputPlaceholder">Пароль</span>
            </label>
          </div>
        )}
        <div className="form-group">
          <textarea
            id="additionalInfo"
            name="additionalInfo"
            rows="4"
            value={formData.additionalInfo}
            onChange={handleChange}
            required
            placeholder="Додатковий спосіб звʼязку (номер телефону / інстаграм / телеграм"
          ></textarea>
        </div>
        <div className="form-group">
          <label
            className={`labelForUserData ${isPromoValid && "promoLabelValid"}`}
            htmlFor="promoCode"
          >
            <input
              className="inputUserDate"
              type="text"
              id="promoCode"
              name="promoCode"
              value={usersPromo}
              onChange={handleChangePromo}
              placeholder="Промо-код для застосування знижки"
            />
          </label>
        </div>

        <label
          className={isLoggedIn ? "labelForUserDataCHide" : "labelForUserDataC"}
        >
          <input
            type="checkbox"
            id="agreement"
            checked={isAgreementChecked}
            onChange={handleAgreementChange}
            required={!isLoggedIn}
          />{" "}
          Я приймаю правила політики конфіденційності та даю згоду на
          автороcсилку на мій email
        </label>

        <button
          className="redBtn redBtnFormPaid"
          type="submit"
          disabled={isLoggedIn ? false : !isAgreementChecked} // Кнопка отключена, если чекбокс не выбран
        >
          {isLoggedIn ? "Перейти до оплати" : "Реєстрація"}
        </button>
      </form>
    </Modal>
  );
};
