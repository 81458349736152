import Footer from "../HFM/Footer";
import Header from "../HFM/Header";
import FlirtHero from "./FlirtHero/FlirtHero";
import FlirtSpeakers from "./FlirtSpeakers/FlirtSpeakers";
import FlirtTariff from "./FlirtTariff/FlirtTariff";


export default function FlirtConferencePage() {
    return (
        <>
            <Header />
            <FlirtHero />
            <FlirtSpeakers />
            <FlirtTariff />
            <Footer />
        </>
    )
}