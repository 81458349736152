import slideCard1 from "./images/slideCard1.webp";
import slideCard2 from "./images/slideCard2.webp";
import slideCard3 from "./images/slideCard3.webp";
import slideCard4 from "./images/slideCard4.webp";
import slideCard5 from "./images/slideCard5.webp";
import slideCard6 from "./images/slideCard6.webp";
import slideCard7 from "./images/slideCard7.webp";
import slideCard8 from "./images/slideCard8.webp";
import slideCard9 from "./images/slideCard9.webp";
import slideCard10 from "./images/slideCard10.webp";
import slideCard11 from "./images/slideCard11.webp";

export const imagesData = [
  `${slideCard1}`,
  `${slideCard2}`,
  `${slideCard3}`,
  `${slideCard4}`,
  `${slideCard5}`,
  `${slideCard6}`,
  `${slideCard7}`,
  `${slideCard8}`,
  `${slideCard9}`,
  `${slideCard10}`,
  `${slideCard11}`,
];
