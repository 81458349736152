import styles from './Header.module.css'
import sprite from '../../icons.svg'
import { Link } from 'react-router-dom';
import { BurgerMenuModalRetrite } from './Modals';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectIsLoggedIn } from '../../../redux/auth/selectors';
import LogIn from '../../Modals/LogIn/LogIn';
import ProfileModal from '../../Modals/ProfileModal/ProfileModal';

export default function HeaderR() {
  const [modalPrizeIsOpen, setModalPrizeOpen] = useState(false)
  
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const [modalLogInIsOpen, setModalLogIn] = useState(false);
  const [modalProfileIsOpen, setModalProfile] = useState(false);
  const [modalMode, setModalMode] = useState("login");

    const openModalLogin = (mode) => {
      setModalMode(mode);
      setModalLogIn(true);
    };

    return (
      <header className={styles.headerRetrite} id='header'>
        <Link to="/">
          <svg className={styles.mainLogoIMGRetrite} width={130} height={40}>
            <use xlinkHref={`${sprite}#icon-womenPlace`}></use>
          </svg>
        </Link>
        <button className={styles.contactUsBtnRetrite}>
          Звʼязатись з нами
        </button>
        <button
          className={styles.LogInBtn}
          onClick={() =>
            isLoggedIn ? setModalProfile(true) : setModalLogIn(true)
          }
        >
          <svg className={styles.logInBtnSVG} width={22} height={22}>
            <use xlinkHref={`${sprite}#icon-user`}></use>
          </svg>
          <span className={styles.LogInBtnText}>
            {isLoggedIn ? "мій профіль" : "вхід"}
          </span>
        </button>
        <button
          className={
            isLoggedIn ? styles.RegistrationBtnHide : styles.RegistrationBtn
          }
          onClick={() => openModalLogin("registration")}
        >
          реєстрація
        </button>
        <svg
          className={styles.headerBurgerMenuBtnRetrite}
          width={40}
          height={40}
          onClick={() => setModalPrizeOpen(true)}
        >
          <use xlinkHref={`${sprite}#icon-burger-menu`}></use>
        </svg>
        <BurgerMenuModalRetrite
          isOpen={modalPrizeIsOpen}
          onClose={() => setModalPrizeOpen(false)}
        ></BurgerMenuModalRetrite>
        <LogIn
          isOpen={modalLogInIsOpen}
          onClose={() => setModalLogIn(false)}
          mode={modalMode}
        ></LogIn>
        <ProfileModal
          isOpen={modalProfileIsOpen}
          onClose={() => setModalProfile(false)}
          mode={modalMode}
        ></ProfileModal>
      </header>
    );
}