import Modal from "react-modal";
import styles from "./ResetPassword.module.css";
import sprite from "../../icons.svg";
import axios from "axios";

export default function ResetPasswordModal({ isOpen, onClose }) {
  const handleSubmit = async (e) => {
    e.preventDefault();
    const email = e.target.elements.userEmailLogIn.value;
    try {
      const response = await axios.post("auth/send-reset-email", { email });
      console.log("====================================");
      console.log(response);
      console.log("====================================");
    } catch (error) {
      alert(
        "Сталася помилка! Перевірте правильність написання пошти або спробуйте пізніше."
      );
      return;
    }
    alert(
      "Лист з інструкцією по відновленню пароля було надіслано на вашу пошту!"
    );
  };
  return (
    <Modal
      isOpen={isOpen}
      overlayClassName={styles.modalResetPassword}
      className={styles.modalContentResetPassword}
      closeTimeoutMS={400}
      onRequestClose={onClose}
      ariaHideApp={false}
    >
      <svg
        className={styles.closeModalResetPassword}
        onClick={onClose}
        width={24}
        height={24}
      >
        <use xlinkHref={`${sprite}#icon-close`}></use>
      </svg>
      <h2 className={styles.titleResetPass}>Відновлення паролю</h2>
      <p className={styles.textResetPass}>
        Введіть email-адресу, на яку ви реєстрували свій обліковий запис, і ми
        відправимо на неї лінк для відновлення.
      </p>
      <form onSubmit={(e) => handleSubmit(e)}>
        <div className={styles.formGroupLogIn}>
          <label htmlFor="userEmailLogIn" className={styles.logInLabel}>
            <input
              className={styles.inputUserEmailLogIn}
              type="email"
              id="userEmailLogIn"
              name="userEmailLogIn"
              required
            />
            <span className={styles.spanInputPlaceholder}>Email</span>
          </label>
        </div>
        <button className={styles.ResetPasswordBtn} type="submit">
          Надіслати
        </button>
      </form>
    </Modal>
  );
}
