import Header from "../HFM/Header";
import ProhibitionHero from "./ProhibitionHero/ProhibitionHero";
import ProhibitionSpeakers from "./ProhibitionSpeakers/ProhibitionSpeakers";
import ProhibitionTariff from "./ProhibitionTariff/ProhibitionTariff";
import Footer from '../HFM/Footer'

export default function ProhibitionPage(){
    return (
      <>
        <Header />
        <ProhibitionHero />
        <ProhibitionSpeakers />
        <ProhibitionTariff />
        <Footer />
      </>
    );
}