import style from "./AggreTariff.module.css";
import sprite from "../../../icons.svg";
import { ChoiseAggreVideo } from "../ModulesAggre";
import { useState } from "react";

export default function AggreTariff() {
  const [modalPrizeIsOpen, setModalPrizeOpen] = useState(false);
  return (
    <section className={style.aggreTariffSection} id="tariffSection">
      <h2 className={style.titleAggreTariffSection}>/Тарифи</h2>
      <ul className={style.wrapperBtnsForBuyFC}>
        <li>
          <p className={style.aggrePriceForTariff}>49€</p>
          <p className={style.aggreInfoForPrice}>
            <svg
              className={style.aggreSpiralForPriceSVG}
              width={27}
              height={27}
            >
              <use xlinkHref={`${sprite}#icon-spiralSVG`}></use>
            </svg>
            Відеозапис двох виступів <br /> з конференції на вибір
          </p>
          <p className={style.aggreInfoForPrice}>
            <svg
              className={style.aggreSpiralForPriceSVG}
              width={27}
              height={27}
            >
              <use xlinkHref={`${sprite}#icon-spiralSVG`}></use>
            </svg>
            Доступ на рік
          </p>
          <button
            className={`${style.aggreWhiteBtnTariff} whiteBtn`}
            onClick={() => setModalPrizeOpen(true)}
          >
            Придбати
          </button>
        </li>
        <li>
          <p className={style.aggrePriceForTariff}>159€</p>
          <p className={style.aggreInfoForPrice}>
            <svg
              className={style.aggreSpiralForPriceSVG}
              width={27}
              height={27}
            >
              <use xlinkHref={`${sprite}#icon-spiralSVG`}></use>
            </svg>
            Відеозапис всіх <br /> виступів конференції
          </p>
          <p className={style.aggreInfoForPrice}>
            <svg
              className={style.aggreSpiralForPriceSVG}
              width={27}
              height={27}
            >
              <use xlinkHref={`${sprite}#icon-spiralSVG`}></use>
            </svg>
            Доступ на рік
          </p>
          <button
            className={`${style.aggreWhiteBtnTariff} whiteBtn`}
            onClick={() => setModalPrizeOpen(true)}
          >
            Придбати
          </button>
        </li>
      </ul>
      <ChoiseAggreVideo
        isOpen={modalPrizeIsOpen}
        onClose={() => setModalPrizeOpen(false)}
      ></ChoiseAggreVideo>
    </section>
  );
}
