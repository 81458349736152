export const conveniencesData = [
  "Вихід на пляж",
  "Wi-Fi",
  "Кухня",
  "Все для приготування їжі",
  "Посуд та столові прибори",
  "Відкрита обідня зона",
  "Гриль",
  "Окреме робоче місце",
  "Телевізор",
  "Кондиціонер",
  "Пральна машина",
  "Сушильна машина",
  "Камери відеоспостереження на території помешкання",
];
