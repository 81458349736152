import AnastasiiaMatIMG from "./ProhibitionSpeakersIMG/AnastasiiaMatIMG.webp";
import SvitlanaMarIMG from "./ProhibitionSpeakersIMG/SvitlanaMarIMG.webp";
import BaevaIMG from "./ProhibitionSpeakersIMG/BaevaIMG.webp";
import HayvoronskaIMG from "./ProhibitionSpeakersIMG/HayvoronskaIMG.webp";
import ZadoroznyaIMG from "./ProhibitionSpeakersIMG/ZadoroznyaIMG.webp";
import DiveevaIMG from "./ProhibitionSpeakersIMG/DiveevaIMG.webp";
import ZimovinIMG from "./ProhibitionSpeakersIMG/ZimovinIMG.webp";
import PavluchokIMG from "./ProhibitionSpeakersIMG/PavluchokIMG.webp";
import AdelyaIMG from "./ProhibitionSpeakersIMG/AdelyaIMG.webp";
import DerhausovaIMG from "./ProhibitionSpeakersIMG/DerhausovaIMG.webp";
import ZoriyIMG from "./ProhibitionSpeakersIMG/ZoriyIMG.webp";
import HorobecIMG from "./ProhibitionSpeakersIMG/HorobecIMG.webp";
import PrudnikovaIMG from './FlirtSpeakersIMG/PrudnikovaIMG.webp'
import NesvitIMG from './FlirtSpeakersIMG/NesvitIMG.webp'
import KozachkovaIMG from './FlirtSpeakersIMG/KozachkovaIMG.webp'
import SolohubIMG from './FlirtSpeakersIMG/SolohubIMG.webp'
import AdamovaIMG from './FlirtSpeakersIMG/AdamovaIMG.webp'
import SozanovskaIMG from './FlirtSpeakersIMG/SozanovskaIMG.webp'

export const speakersInfoProhibition = [
  {
    speakerIMG: AnastasiiaMatIMG,
    speakerIMGCSS: "",
    speakerName: "Анастасія-Олімпія Матюшевська",
    speakerInstagram:
      "https://www.instagram.com/anastasia.olimpia.matushevska?igsh=MWZlbGxvd2wzOHEw",
    width: "445",
    height: "445",
    speakerTema: "Кризи жіночої ідентичності як природнє порушення кордонів",
    speakerAbout: [
      "Гештальт-терапевт НАГТУ",
      "Супервізор",
      "Акредитований член EAGT",
      "Спеціаліст по роботі з сексуальною тематикою",
      "Засновниця проектів психологічно-емоційного розвитку «ЖІНКИ»: конференції, ретрит",
    ],
  },
  {
    speakerIMG: BaevaIMG,
    speakerIMGCSS: "speakerIMGBaeva",
    speakerName: "Олена Баєва",
    speakerInstagram:
      "https://www.instagram.com/fannie_flagg?igsh=aWlpbDJjZDhpdjl1",
    width: "445",
    height: "445",
    speakerTema: "Смак заборон",
    speakerAbout: [
      "Гештальт-терапевт",
      "Супервізор",
      "Провідний тренер НАГТУ",
      'Ведуча навчальних програм, спеціалізації "Гештальт-терапія з дітьми та сім`єю", "Теорії розвитку та вікові кризи в Гештальт-терапії"',
    ],
  },
  {
    speakerIMG: SvitlanaMarIMG,
    speakerIMGCSS: "speakerIMGMarchek",
    speakerName: "Світлана Марчек",
    speakerInstagram:
      "https://www.instagram.com/svitlana_marcek?igsh=aTNvNTh5NHM0eGxl",
    width: "445",
    height: "445",
    speakerTema:
      " «На захисті беззахисного». Вплив раннього травматичного досвіду на формування особистих кордонів.",
    speakerAbout: [
      "Гештальт-терапевт",
      "Травматерапевт",
      "Сертифікований арт-терапевт",
      "Спеціаліст з роботи з шоковими травмами, втратами, ПТСР та КПТСР, а також по роботі з ранньою травмою",
    ],
  },
  {
    speakerIMG: PavluchokIMG,
    speakerIMGCSS: "speakerIMGPavluchok",
    speakerName: "Наталія Павлючок",
    speakerInstagram: "https://www.instagram.com/natalia.pavluchok/",
    width: "425",
    height: "425",
    speakerTema: "Ідентичність українок у сучасному світі після вторгнення",
    speakerAbout: [
      "Підприємниця",
      "Амбасадорка здорових сімейних відносин",
      "Співвласниця харчового виробництва",
      "Власниця бутік-готелю",
    ],
  },
  {
    speakerIMG: ZimovinIMG,
    speakerName: "Олексій Зимовін",
    speakerInstagram:
      "https://www.instagram.com/psy_zimovin?igsh=aWZ1anluOGJtNDlj",
    speakerTema: "Від дільожки до обміну: жінки та чоловіки НА МЕЖІ",
    speakerAbout: [
      "Психолог екзистенційно-гуманістичного напряму",
      "Акредитований гештальт-терапевт",
      "Супервізор",
      "Кандидат психологічних наук",
    ],
    width: "475",
    height: "475",
  },
  {
    speakerIMG: AdelyaIMG,
    speakerIMGCSS: "speakerIMGAdelya",
    speakerName: "Аделя Ісламгулова",
    speakerInstagram: "https://www.instagram.com/adelia.islamhulova/",
    speakerTema: "Кожній жінці потрібні її власні гроші",
    speakerAbout: [
      "Ментор по фінансовому мисленню",
      "Співзасновниця @heldelaconstruction",
      "Власниця G.Bar Limassol",
    ],
    width: "445",
    height: "445",
  },
  {
    speakerIMG: DerhausovaIMG,
    speakerIMGCSS: "speakerIMGDerhausova",
    speakerName: "Саша Дергоусова",
    speakerInstagram: "https://www.instagram.com/dergousova/",
    speakerTema: "Як досягають бажаного без боротьби, стресу та суєти.",
    speakerAbout: [
      "Бізнес-women",
      "Засновниця культової весільної агенції Dergousova Agency та бренду постільної білизни Home me",
      "Ментор із гармонійного стану та психічного здоров'я",
      "Випусккниця курсу бізнес єдиної свідомості в ЄКАМ (Індія)",
    ],
    width: "415",
    height: "415",
  },
  {
    speakerIMG: HayvoronskaIMG,
    speakerIMGCSS: "speakerIMGHayvoronska",
    speakerName: "Юлія Гайворонская",
    speakerInstagram:
      "https://www.instagram.com/yuliya_gayvoronskaya?igsh=MXJmOXVmcGtpcTlveA==",
    width: "380",
    height: "380",
    speakerTema: "Безпека у інтимних стосунках",
    speakerAbout: [
      "Секс коуч",
      "Займається психологічним консультуванням в області сексології",
      "Тренер з сексуальної освіти",
      "Власниця магазину для дорослих gayvoronskaya.com",
    ],
  },
  {
    speakerIMG: DiveevaIMG,
    speakerIMGCSS: "speakerIMGDiveeva",
    speakerInstagram: "https://www.instagram.com/olga_diveyeva_/",
    speakerName: "Ольга Дівєєва",
    speakerTema:
      "ВИХІД - психологічний розбір діагнозів та складних ситуацій учасників",
    speakerAbout: [
      "Клінічний психолог, досвід 18 років.",
      "Експерт з психосоматики",
      "Створила Школу психосоматики Ольги Дівєєвої.",
    ],
    width: "415",
    height: "415",
  },
  {
    speakerIMG: ZadoroznyaIMG,
    speakerIMGCSS: "speakerIMGZadoroznya",
    speakerName: "Веронiка Задорожна",
    speakerInstagram: "https://www.instagram.com/veronika.zadorozhnaya/",
    width: "425",
    height: "425",
    speakerTema: "Особисті кордони або чому з вами можно чинити погано!",
    speakerAbout: [
      "Цифровий психолог мільонерів",
      "Мотиваційний спікер, ментор",
      "Майстер НЛП",
      "Автор книг",
      "Підприємниця з 25+ річним досвідом у власному бізнесі",
    ],
  },
  {
    speakerIMG: ZoriyIMG,
    speakerIMGCSS: "speakerIMGZoriy",
    speakerName: "Алла Зорій",
    speakerInstagram: "https://www.instagram.com/alla_zoriy/",
    width: "425",
    height: "425",
    speakerTema:
      "Як перепрограмувати свій мозок на досягнення своїх бажаних цілей",
    speakerAbout: [
      "Лікар, медичний психолог",
      "17 років практичної діяльності",
      "Експерт у сфері сімейної, клієнт-центрованої психотерапії, НЛП психології бізнесу",
      "Керівниця Благодійного Фонду",
      "«Світ Майбутнього»",
      "Керівниця тренінгового центру",
      "AZ training center",
    ],
  },
  {
    speakerIMG: HorobecIMG,
    speakerIMGCSS: "speakerIMGHorobec",
    speakerName: "Марися Горобець",
    speakerInstagram: "https://www.instagram.com/marysyagorobets/",
    width: "245",
    height: "245",
    speakerTema:
      "Жіночі кордони та сила мережування: як порушувати правила, залишаючись вірною собі",
    speakerAbout: [
      "Амбасадор культурної дипломатії",
      "Ведуча благодійних та мистецьких подій",
      "Провела та організувала понад 2500 подій",
      "Об'їздила мандрівним журналістом 75 країн світу",
      "Пройшла шлях від ведучої до генерального продюсера каналу",
    ],
  },
];

export const prohibitionVideos = [
  {
    id: "MatushevskaProhibition",
    link: "https://www.youtube.com/embed/zLnQ2z-vUiU?si=TlgFEu8Cr9ve3SBX",
  },
  {
    id: "BaevaProhibition",
    link: "https://www.youtube.com/embed/YG9cpgGx9l0?si=d7IL1iZ8ZDaiFvMD",
  },
  {
    id: "MarchekProhibition",
    link: "https://www.youtube.com/embed/olCs03Nx9Ko?si=IAx2KLLVtbLXc2g1",
  },
  {
    id: "PavluchokProhibition",
    link: "https://www.youtube.com/embed/rNpDFFIdrWk?si=eN5eBmXiLF-4WPDT",
  },
  {
    id: "ZimovinProhibition",
    link: "https://www.youtube.com/embed/3ZvPbGTDvDE?si=H9-tNtEsc1l-uLOt",
  },
  {
    id: "IslamhulovaProhibition",
    link: "https://www.youtube.com/embed/P4FryPkSaPo?si=2-95eJsluslQ2yno",
  },
  {
    id: "DerhousovaProhibition",
    link: "https://www.youtube.com/embed/SqetLU28FOQ?si=SSECG3Ys6TZup7Xp",
  },
  {
    id: "HayvoronskaProhibition",
    link: "https://www.youtube.com/embed/xHHlyJRq7NU?si=bcGGj04vPSLBHbe2",
  },
  {
    id: "DiveevaProhibition",
    link: "https://www.youtube.com/embed/BSkKYZKoe-Q?si=ttya2HT29Jo2umaV",
  },
  {
    id: "ZadoroznaProhibition",
    link: "https://www.youtube.com/embed/dzcvs4_o8NA?si=ZKi1SP-CTi6XaeRp",
  },
  {
    id: "ZoriyProhibition",
    link: "https://www.youtube.com/embed/gOtmLS35sLg?si=n4TeFalIU2YuIDnY",
  },
  {
    id: "HorobecProhibition",
    link: "https://www.youtube.com/embed/XIeLhD7XiFI?si=he7RDFKnwzQH-14t",
  },
];

export const speakersInfoFlirt = [
    {
      speakerIMG: AnastasiiaMatIMG,
      speakerIMGCSS: "",
      speakerName: "Анастасія-Олімпія Матюшевська",
      speakerInstagram:
        "https://www.instagram.com/anastasia.olimpia.matushevska?igsh=MWZlbGxvd2wzOHEw",
      width: "445",
      height: "445",
      speakerTema: "Бажання дражнити, щоб вас піддразнювали",
      speakerAbout: [
        "Гештальт-терапевт НАГТУ",
        "Супервізор",
        "Акредитований член EAGT",
        "Спеціаліст по роботі з сексуальною тематикою",
        "Засновниця проектів психологічно-емоційного розвитку «ЖІНКИ»: конференції, ретрит",
      ],
    },
    {
      speakerIMG: BaevaIMG,
      speakerIMGCSS: "speakerIMGBaeva",
      speakerName: "Олена Баєва",
      speakerInstagram:
        "https://www.instagram.com/fannie_flagg?igsh=aWlpbDJjZDhpdjl1",
      width: "445",
      height: "445",
      speakerTema:
        "Театр жіночого життя: гра як форма розвитку, атмосфера гри, жіночі ігри",
      speakerAbout: [
        "Гештальт-терапевт",
        "Супервізор",
        "Провідний тренер НАГТУ",
        'Ведуча навчальних програм, спеціалізації "Гештальт-терапія з дітьми та сім`єю", "Теорії розвитку та вікові кризи в Гештальт-терапії"',
      ],
    },
    {
      speakerIMG: SvitlanaMarIMG,
      speakerIMGCSS: "speakerIMGMarchek",
      speakerName: "Світлана Марчек",
      speakerInstagram:
        "https://www.instagram.com/svitlana_marcek?igsh=aTNvNTh5NHM0eGxl",
      width: "445",
      height: "445",
      speakerTema: "Флірт як втеча від близькості",
      speakerAbout: [
        "Гештальт-терапевт",
        "Травматерапевт",
        "Сертифікований арт-терапевт",
        "Спеціаліст з роботи з шоковими травмами, втратами, ПТСР та КПТСР, а також по роботі з ранньою травмою",
      ],
    },
    {
      speakerIMG: PrudnikovaIMG,
      speakerIMGCSS: "speakerIMGPrudnikova",
      speakerName: "Вікторія Пруднікова",
      speakerInstagram:
        "https://www.instagram.com/prudnikovavictoria?igsh=eXZuOTZ1aWNjMm5v",
      width: "445",
      height: "445",
      speakerTema: "Аудит життя",
      speakerAbout: [
        "Експерт з ментальної генетики",
        "Консультант",
        "Соціолог",
        "Блогер",
        "Автор методики «PPF Past Present Future» та «АУДИТИ ЖИТТЯ»",
        "Автор проекту Наставництва «Жінка. Гроші. Призначення»",
      ],
    },
    {
      speakerIMG: NesvitIMG,
      speakerIMGCSS: "speakerIMGNesvit",
      speakerName: "Костянтин Несвіт",
      speakerInstagram:
        "https://www.instagram.com/knesvit?igsh=MXVobzZ0a29jYzB1OQ==",
      width: "385",
      height: "385",
      speakerTema: "Флірт як практична навичка. Елементи флірту та їх розвиток",
      speakerAbout: [
        "Чоловічий психоло",
        "10 років практики",
        "автор книги і засновник «Чоловічого психологічного клубу»",
        "Працює з клієнтами рівня ТОП 40 Forbes",
        "Спеціалізується на темі стосунків та їх відсутності.",
      ],
    },
    {
      speakerIMG: KozachkovaIMG,
      speakerIMGCSS: "speakerIMGKozachkova",
      speakerName: "Юлія Козачкова",
      speakerInstagram:
        "https://www.instagram.com/kozachkova_yuliia?igsh=M3czeG0zMjZ3Mzlq",
      width: "385",
      height: "385",
      speakerTema: "Жіночність та гроші",
      speakerAbout: [
        "Психолог-практик",
        "Коуч",
        "Майстер НЛП",
        "Фасилітатор ТоР Owner додатку Teya та Teya Shop",
        "Co-creator «Kozachkova club»",
        "Автор серії книг «Мистецтво бути в ЦЕЙ:ЧАС»",
        "Co-owner маркетингової агенції «Спокусливий бренд»",
      ],
    },
    {
      speakerIMG: SolohubIMG,
      speakerIMGCSS: "speakerIMGSolohub",
      speakerName: "Ольга Сологуб",
      speakerInstagram:
        "https://www.instagram.com/olga.sologub?igsh=ZGcyNGk3M2dtNmg=",
      width: "425",
      height: "425",
      speakerTema: "Флірт як стиль життя",
      speakerAbout: [
        "Психолог",
        "Сексолог",
        "Супервізор",
        "Груповий терапевт",
        "Тренер інституту MIGIS",
        "Автор програми « СЕКСОЛОГІЯ і Гештальт терапія»",
        "Автор програми «Сексуальне виховання дітей»",
      ],
    },
    {
      speakerIMG: AdamovaIMG,
      speakerIMGCSS: "speakerIMGAdamova",
      speakerName: "Лєна Адамова",
      speakerInstagram:
        "https://www.instagram.com/adamova_elena__?igsh=MTI5MnZ2M2hsOHZwZQ==",
      width: "425",
      height: "425",
      speakerTema: "Мова спілкування сексуальності",
      speakerAbout: [
        "Кандидат юридичних наук",
        "Психолог",
        "Психосоматолог",
        "Експерт-психолог",
        "Коуч-трансформатор рубрики «Жити легко з Адамовою» на ТРК Україна",
      ],
    },
    {
      speakerIMG: PavluchokIMG,
      speakerIMGCSS: "speakerIMGPavluchok",
      speakerName: "Наталія Павлючок",
      speakerInstagram: "https://www.instagram.com/natalia.pavluchok/",
      width: "425",
      height: "425",
      speakerTema: "Ідентичність та флірт",
      speakerAbout: [
        "Підприємниця",
        "Амбасадорка здорових сімейних відносин",
        "Співвласниця харчового виробництва",
        "Власниця бутік-готелю",
      ],
    },
    {
      speakerIMG: HayvoronskaIMG,
      speakerIMGCSS: "speakerIMGHayvoronska",
      speakerName: "Юлія Гайворонская",
      speakerInstagram:
        "https://www.instagram.com/yuliya_gayvoronskaya?igsh=MXJmOXVmcGtpcTlveA==",
      width: "380",
      height: "380",
      speakerTema: "Як навчитися любити флірт та себе в ньому",
      speakerAbout: [
        "Секс коуч",
        "Займається психологічним консультуванням в області сексології",
        "Тренер з сексуальної освіти",
        "Власниця магазину для дорослих gayvoronskaya.com",
      ],
    },
    {
      speakerIMG: SozanovskaIMG,
      speakerIMGCSS: "speakerIMGSozanovska",
      speakerName: "Леся Матвєєва-Созановська",
      speakerInstagram:
        "https://www.instagram.com/lesiamatveyeva?igsh=MXFudDZ0djc3MnB2Ng==",
      width: "460",
      height: "460",
      speakerTema: "Як підвищити самооцінку та стати впевненою у собі.",
      speakerAbout: [
        "Практичний психолог та психоаналітик",
        "Консультант з особистісного розвитку",
        "Тренер з впевненості у собі",
        "Член Національної Психологічної Асоціації",
      ],
    },
  ];