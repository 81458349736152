import { useState } from "react";
import styles from "./BookingModal.module.css";
import Modal from "react-modal";
import sprite from "../../icons.svg";
import axios from "axios";

import iconImage from "./icon.webp";

export const BookingModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSecondModalOpen, setIsSecondModalOpen] = useState(false);
  const [isThirdModalOpen, setIsThirdModalOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const email = e.target.elements.email.value;
    const name = e.target.elements.name.value;
    const phoneNumber = e.target.elements.phoneNumber.value;

    const response = await axios.post("https://women.place/retrite/users", {
      email,
      name,
      phoneNumber,
    });
    console.log(response);

    closeModal();
    setIsSecondModalOpen(true);
  };
  return (
    <>
      <button className={styles.reservationButton} onClick={() => openModal()}>
        Забронювати
      </button>
      <Modal
        isOpen={isOpen}
        overlayClassName={styles.modalOverlay}
        className={styles.modalContainer}
        closeTimeoutMS={400}
        onRequestClose={() => closeModal()}
        ariaHideApp={false}
      >
        <h2 className={styles.modalTitle}>Забронювати місце</h2>
        <p className={styles.modalText}>
          Ми звʼяжемось з вами <br className={styles.onlyOnMobile} /> протягом
          24 годин!
        </p>
        <form className={styles.bookingForm} onSubmit={(e) => handleSubmit(e)}>
          <input
            className={styles.bookingFormInput}
            placeholder="Ім'я"
            type="text"
            name="name"
            required
          />
          <input
            className={styles.bookingFormInput}
            placeholder="Номер телефону"
            type="text"
            name="phoneNumber"
            required
          />
          <input
            className={styles.bookingFormInput}
            placeholder="Електронна пошта"
            type="email"
            name="email"
            required
          />
          <button type="submit" className={styles.submitButton}>
            Надіслати
          </button>
        </form>
        <button className={styles.closeButton} onClick={() => closeModal()}>
          <svg className={styles.closeButtonIcon}>
            <use xlinkHref={`${sprite}#icon-close `}></use>
          </svg>
        </button>
      </Modal>
      <Modal
        isOpen={isSecondModalOpen}
        overlayClassName={styles.modalOverlay}
        className={styles.modalContainer}
        closeTimeoutMS={400}
        onRequestClose={() => setIsSecondModalOpen(false)}
        ariaHideApp={false}
      >
        <h2 className={styles.modalTitle}>
          ваша заявка <br className={styles.onlyOnMobile} /> прийнята!
        </h2>
        <p className={styles.waitForCallText}>
          Очікуйте нашого дзвінка протягом 24 годин для підтвердження
          бронювання.
        </p>
        <img src={iconImage} alt="" className={styles.iconImage} />
        <button
          type="submit"
          className={styles.submitButton}
          onClick={() => {
            setIsSecondModalOpen(false);
            setIsThirdModalOpen(true);
          }}
        >
          ДОБРЕ!
        </button>
      </Modal>
      <Modal
        isOpen={isThirdModalOpen}
        overlayClassName={styles.modalOverlay}
        className={styles.modalContainer}
        closeTimeoutMS={400}
        onRequestClose={() => setIsSecondModalOpen(false)}
        ariaHideApp={false}
      >
        <p className={styles.questionsText}>
          Якщо у Вас залишились питання ви можете звʼязатись з нами особисто:
        </p>
        <div className={styles.contactsIconsContainer}>
          <a
            target="_blank"
            href="https://www.instagram.com/anastasia.olimpia.matushevska?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
          >
            <svg className={styles.contactIcon}>
              <use xlinkHref={`${sprite}#icon-instagram `}></use>
            </svg>
          </a>
          <a target="_blank" href="https://t.me/women_psyconference">
            <svg className={styles.contactIcon}>
              <use xlinkHref={`${sprite}#icon-telegram `}></use>
            </svg>
          </a>
        </div>
        <ul className={styles.additionalInfoList}>
          <li>
            <a
              href="mailto:garbaruk.psyfamily@gmail.com"
              className={styles.contactLink}
            >
              garbaruk.psyfamily@gmail.com
            </a>
          </li>
          <li>
            <a href="tel:+380936576711" className={styles.contactLink}>
              +38 (093) 657 6711
            </a>
          </li>
          <li>
            <p className={styles.timeSchedule}>
              Графік роботи: з 10:00 до 20:00
            </p>
          </li>
        </ul>
        <button
          type="submit"
          className={styles.submitButton}
          onClick={() => {
            setIsThirdModalOpen(false);
          }}
        >
          ДОБРЕ!
        </button>
      </Modal>
    </>
  );
};
