import styles from "./Reviews.module.css";

import { Scrollbar, Virtual } from "swiper/modules";
import { useState, useEffect } from "react";

import "swiper/css";
import "swiper/css/scrollbar";
import { SwiperSlide, Swiper } from "swiper/react";

export default function Reviews() {
  const [slidesPerView, setSlidesPerView] = useState(getSlidesPerView());

  useEffect(() => {
    function handleResize() {
      setSlidesPerView(getSlidesPerView());
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  function getSlidesPerView() {
    return window.innerWidth >= 455 ? 2 : 1;
  }

  const aggreReviewsJSON = [
    {
      reviewerName: "oks_kozlova",
      reviewerComment:
        "Конференція дійсно класна. Звільнила ці дні, аби слухати, бо інформація підібрана чітко, тема До речі, енергія теж піднімалася гарна та продуктивність була висока після завершення виступу спікерів. Не було відчуття виснаженості після 6 виступів.Радію за тебе та успішну реалізацію того, що колись було просто ідеєю. Бажаю маштабування, бо це точно є і цінним матеріалом, і корисним. І дякую твоїй команді та підтримуючому оточенню, що були поряд і допомогли це втілити в реальність.",
    },
    {
      reviewerName: "lydiadrozd",
      reviewerComment:
        "Спасибі, Настю, за Вашу роботу, за конференціію нових смислів, за можливість на два дні зануритися у себе доторкнутися до світів цікавих людей, які привідкрили завісу до таємничого світу Жінки. Сиджу в кафе, конференція закінчилася, а мені хочеться побути ще кілька хвилин, щоб враження вляглися. Я знаю, що зерна, посіяні на конференції, впали на добрий грунт. Ви робите цінну роботу. Дякую Вам",
    },
    {
      reviewerName: "angelina_008",
      reviewerComment:
        "Настя, поздравляю с дебютом конференции. Это было очень интересно, ресурсно и прекрасно. Спасибо что делаешь такие вдохновляющие проэкты",
    },
    {
      reviewerName: "elena.stepanova_teta_realnovse",
      reviewerComment:
        "Настя, спасибо за крутых спикеров. Получила эмоциональное и интеллектуальное удовольствие. Спасибо большое.",
    },
    {
      reviewerName: "Oleksandra Rukina",
      reviewerComment:
        "Дякую всім за таку чудову конференцію! Мені дуже сподобалось!",
    },
    {
      reviewerName: "Ліда Дрозд",
      reviewerComment:
        "Так добре побути в полі цінних жінок, як Олена. Затишно, зцілююче. Дякую, Настю",
    },
    {
      reviewerName: "Елена Степанова",
      reviewerComment:
        "Дякую, мені корисно було почути, що чим сильніше чоловік, тим більше він здатен до рівного погляду очей.",
    },
    {
      reviewerName: "Ксюша",
      reviewerComment:
        "Дякую за розкриття такої теми. Ваш приклад афігенний, що жінка, це ЖІНКА, а не аксесуар чоловіка",
    },
    {
      reviewerName: "Яна Тарасенко",
      reviewerComment:
        "Дякую вам, на одному диханні прослухала, ручка кипіла в руках",
    },
    {
      reviewerName: "Natali Saprykina",
      reviewerComment:
        "Я вообще должна была выходить из дома, но не могу оторваться и лишить себя удовольствия слушать и смотреть",
    },
    {
      reviewerName: "Пользователь Zoom",
      reviewerComment:
        "Світлана, дуже вдячна! Є усвідомлення, що мама карала мене мовчанням. Зараз, і в мене присутня та сама модель по відношенню до чоловіка!",
    },
    {
      reviewerName: "Natalia Popova",
      reviewerComment:
        "В Вас так много энергии и жизни Благодарю. Очень интересно было смотреть",
    },
    {
      reviewerName: "Оксана",
      reviewerComment:
        "Ви розширили мої границі, дуже сподобалося порівняння що Жінка-це Країна. Дякую",
    },
    {
      reviewerName: "Oksana Kozak",
      reviewerComment:
        "Спасибо, что много акцентов на сестринство, это правда очень важно! Отозвалось каждое слово! Плакала! Анализировала агрессии, радовалась, что наконец узнала многие вещи! Благодарю!!!!",
    },
    {
      reviewerName: "Dasha Zhuravina",
      reviewerComment:
        "Очень глубокая информация! Благодарю, вас очень интересно слушать!",
    },
    {
      reviewerName: "Ruslana",
      reviewerComment:
        "Не хочеться щоб закінчувалась лекція.. кожне слово ловлю.",
    },
  ];
  return (
    <section className={styles.sectionReviews} id="sectionReviews">
      <div className="container">
        <h2 className={styles.titleReviews}>/відгуки учасників</h2>

        <Swiper
          spaceBetween={20}
          slidesPerView={slidesPerView}
          modules={[Scrollbar, Virtual]}
          scrollbar={{
            draggable: true,
          }}
          autoHeight={true}
          className={`${styles.mySwiperReviews} ${styles.reset}`}
        >
          {aggreReviewsJSON.map((aggreReviewsJSON, index) => (
            <SwiperSlide key={index}>
              <h3 className={styles.titleNameReviewer}>
                {aggreReviewsJSON.reviewerName}
              </h3>
              <p className={styles.commentFromReviewer}>
                {aggreReviewsJSON.reviewerComment}
              </p>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
}
