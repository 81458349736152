import Modal from "react-modal";
import styles from "./WebViewTriggerModal.module.css";
import sprite from "../../icons.svg";
Modal.setAppElement("#root");
const WebViewTriggerModal = ({ isOpen, closeModal }) => {
  return (
    <div>
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        className={styles.modalContent}
        overlayClassName={styles.modalOverlay}
        contentLabel="Open in Browser Modal"
      >
        <h2 className={styles.modalTitle}>Попереджаємо!</h2>
        <p className={styles.modalText}>
          Для повної та коректної роботи сайту рекомендується відкривати його в
          зовнішньому браузері.
        </p>
        <button className={styles.closeButton} onClick={() => closeModal()}>
          <svg className={styles.closeIcon}>
            <use xlinkHref={`${sprite}#icon-close`}></use>
          </svg>
        </button>
      </Modal>
    </div>
  );
};

export default WebViewTriggerModal;
