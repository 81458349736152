import donationIMG from "./donationIMG.webp";
import donatinUkraineIMG from "./donationUkraineIMG.png";
import style from "./Donation.module.css";

export default function Donation() {
  return (
    <section className="donationSection" id="donationSection">
      <div className="container">
        <h2 className={style.titleDonation}>/донат</h2>
        <div className={style.wrapperDonationIMG}>
          <img
            loading="lazy"
            className={style.donationIMG}
            src={donationIMG}
            alt="Soldats"
            width={335}
            height={135}
          />
          <a
            target="_blank"
            href="https://www.instagram.com/imyourrear.com.ua/"
            className={style.linkToAnotherSite}
          >
            Сайт для ознайомлення
          </a>
        </div>
        <ul className={style.wrapperDonationInfo}>
          <li>
            <h3 className={style.titleDonationInfo}>
              Донатимо благодійному фонду <span>«Я буду твоїм тилом»</span> на
              потреби бійців на передовій
            </h3>
            <p className={style.textDonationInfo}>
              35 FPV дронів - камікадзе для 7 бригад у трьох напрямках лінії
              фронту.
            </p>
            <p className={style.textDonationInfoTwo}>
              Віддавайте те, що ще зможемо заробити, поки в наших містах
              жовто-блакитний прапор, щоб не прийшлось віддавати те, що, дається
              один раз - життя.
            </p>
            <h4 className={style.titleDonationInfoTwo}>
              Загальна сума збору <br /> <span>1 705 000 грн</span>
            </h4>
            <a //First li
              href="https://send.monobank.ua/jar/4F2E3FHiK6"
              className={`redBtn  ${style.donationUkraineIMGMobile}`}
              target="_blank"
            >
              Задонатити на перемогу!
            </a>
            <img
              loading="lazy"
              className={`${style.donationUkraineIMG} ${style.donationUkraineIMGHideDesc}`}
              src={donatinUkraineIMG}
              alt="Ukraine"
              width={335}
              height={57}
            />
          </li>
          <li>
            <p className={style.textForOnDonation}>
              Важливий, великий збір на НИЩІВНИЙ РІЙ дронів для ураження ворожих
              цілей.
            </p>
            <p className={style.textForOnDonatin}>
              Офіційні запити від 7 бригад вже прораховані в суму закупівлі:
            </p>
            <p className={style.textUl}>1 ОМБ «Вовки Да Вінчі» ДУК</p>
            <p className={style.textUl}>92 ОШБр</p>
            <p className={style.textUl}>25 ОСБ</p>
            <p className={style.textUl}>409 ОСБ</p>
            <p className={style.textUl}>23 ОСБ</p>
            <p className={style.textUl}>78 полк «Герць»</p>
            <p className={style.textUl}>Добровольчий підрозділ ФРАЙКОР</p>
            <img //last li
              loading="lazy"
              className={`${style.donationUkraineIMG} ${style.donationUkraineIMGMobile}`}
              src={donatinUkraineIMG}
              alt="Ukraine"
              width={335}
              height={57}
            />
            <a
              href="https://send.monobank.ua/jar/4F2E3FHiK6"
              className={`redBtn ${style.redBtnDonation} ${style.donationUkraineIMGHideDesc}`}
              target="_blank"
            >
              Задонатити на перемогу!
            </a>
          </li>
        </ul>
      </div>
    </section>
  );
}
