import style from "./Speakers.module.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import AnastasiiaMatIMG from "./AnastasiiaMatIMG.webp";
// import SvitlanaMarIMG from "./SvitlanaMarIMG.webp";
// import BaevaIMG from "./BaevaIMG.webp";
// // import PrudnikovaIMG from "./PrudnikovaIMG.webp";
// // import NesvitIMG from "./NesvitIMG.webp";
// // import KozachkovaIMG from "./KozachkovaIMG.webp";
// // import SolohubIMG from "./SolohubIMG.webp";
// // import AdamovaIMG from "./AdamovaIMG.webp";
// import HayvoronskaIMG from "./HayvoronskaIMG.webp";
// // import SozanovskaIMG from "./SozanovskaIMG.webp";
// import ZadoroznyaIMG from "./ZadoroznyaIMG.webp";
// import DiveevaIMG from "./DiveevaIMG.webp";
// import ZimovinIMG from "./ZimovinIMG.webp";
// import PavluchokIMG from "./PavluchokIMG.webp";
// import AdelyaIMG from "./AdelyaIMG.webp";
// import DerhausovaIMG from "./DerhausovaIMG.webp";
// import ZoriyIMG from "./ZoriyIMG.webp";
// import HorobecIMG from './HorobecIMG.webp'
import sprite from "../../icons.svg";
import "swiper/css/scrollbar";
import { Scrollbar, Pagination, Navigation, Virtual } from "swiper/modules";
import "swiper/css/pagination";
import { useState } from "react";
import { ChoiseTariffModal } from "../HFM/Modals/ChoiseTariffModal";
import { selectIsLoggedIn } from "../../../redux/auth/selectors";
import { useSelector } from "react-redux";
import LogIn from "../../Modals/LogIn/LogIn";

export default function Speakers() {
  const speakersJSON = [
    {
      speakerIMG: AnastasiiaMatIMG,
      speakerIMGCSS: "",
      speakerName: "Анастасія-Олімпія Матюшевська",
      speakerInstagram:
        "https://www.instagram.com/anastasia.olimpia.matushevska?igsh=MWZlbGxvd2wzOHEw",
      width: "445",
      height: "445",
      speakerTema: "Кризи жіночої ідентичності як природнє порушення кордонів",
      speakerAbout: [
        "Гештальт-терапевт НАГТУ",
        "Супервізор",
        "Акредитований член EAGT",
        "Спеціаліст по роботі з сексуальною тематикою",
        "Засновниця проектів психологічно-емоційного розвитку «ЖІНКИ»: конференції, ретрит",
      ],
    },
  ];
  const [modalPrizeIsOpen, setModalPrizeOpen] = useState(false);
  const [modalLogInIsOpen, setModalLogIn] = useState(false);

  const isLoggedIn = useSelector(selectIsLoggedIn);

  return (
    <section className={style.speakersSection} id="speakersSection">
      <div className="container">
        <h2 className={style.titleSpeakers}>/спікери конференції</h2>
      </div>
      <ul className={style.wrapperAboutWhatSpeakers}>
        <li className={style.wrapperAboutWhatSpeakersLi}>
          <p className={style.textHowMutchSpeakers}>12</p>
          <p className={style.textAnswerHowMutchSpeakers}>
            поглядів на одну тему
          </p>
        </li>
        <li className={style.wrapperAboutWhatSpeakersLi}>
          <p className={style.textAnswerHowMutchSpeakersTwo}>
            Спікери конференцій — сертифіковані експерти, які із різних боків
            розриють тему{" "}
            <span className={style.temaInredColor}>
              турботи та здатність турбуватись
            </span>{" "}
            та нададуть інструменти для пізнання та усвідомлення жінки.
          </p>
        </li>
      </ul>
      <div className="container">
        <div
          className={`${style.mySwiperSpeakersNavigation} swiper-navigation`}
        >
          <div className={style.swiperButtonPrev}>
            <svg
              onClick={(e) => e.preventDefault()}
              className=""
              width={60}
              height={60}
              fill="white"
            >
              <use xlinkHref={`${sprite}#arrow-right`}></use>
            </svg>
          </div>
          <div className={style.swiperButtonNext}>
            <svg
              onClick={(e) => e.preventDefault()}
              className=""
              width={60}
              height={60}
              fill="white"
            >
              <use xlinkHref={`${sprite}#arrow-left`}></use>
            </svg>
          </div>
        </div>
        <Swiper
          slidesPerView={1}
          spaceBetween={40}
          // autoHeight={true}
          scrollbar={{
            draggable: true,
          }}
          pagination={{
            type: "fraction",
          }}
          navigation={{
            prevEl: `.${style.swiperButtonPrev}`,
            nextEl: `.${style.swiperButtonNext}`,
          }}
          modules={[Scrollbar, Pagination, Navigation, Virtual]}
          className={style.mySwiperSpeakers}
        >
          {speakersJSON.map((speaker, index) => (
            <SwiperSlide key={index} virtualIndex={index}>
              <div className={style.wrapperSpeakerInfo}>
                <div className={style.wrapperSpeakerPhoto}>
                  <img
                    className={`${style.speakerIMG} ${
                      style[speaker.speakerIMGCSS]
                    }`}
                    src={speaker.speakerIMG}
                    alt={speaker.speakerName}
                    width={speaker.width}
                    height={speaker.height}
                  />
                  <span className={style.ellipsesBehindSpeakers}></span>
                </div>
                <div className={style.wrapperSpeakerName}>
                  <p className={style.titleSpeakerName}>
                    {speaker.speakerName}
                  </p>
                  <a
                    alt="Instagram"
                    aria-label="Перейти на Instagram"
                    target="_blank"
                    href={speaker.speakerInstagram}
                    className={style.linkToInstagramSpeaker}
                  >
                    <svg className={style.instagramSVG} width={60} height={60}>
                      <use xlinkHref={`${sprite}#icon-instagram`}></use>
                    </svg>
                  </a>
                </div>
                <Swiper
                  slidesPerView={1}
                  spaceBetween={40}
                  pagination={{ clickable: true }}
                  modules={[Pagination]}
                  autoHeight={true}
                  className={style.mySwiperSpeakersMobile}
                >
                  <SwiperSlide>
                    <h3 className={style.titleThisInfo}>Тема виступу:</h3>
                    <p className={style.textThisInfo}>{speaker.speakerTema}</p>
                  </SwiperSlide>
                  <SwiperSlide>
                    <h3 className={style.titleThisInfo}>Про спікера:</h3>
                    <ul className={style.wrapperTextThisInfo}>
                      {speaker.speakerAbout.map((about, idx) => (
                        <li className={style.textThisInfoLi} key={idx}>
                          {about}
                        </li>
                      ))}
                    </ul>
                  </SwiperSlide>
                  <SwiperSlide>
                    <h3 className={style.titleThisInfo}>Дата виступу:</h3>
                    <p className={style.textThisInfo}>27 - 28 жовтня 2024</p>
                  </SwiperSlide>
                </Swiper>
              </div>
              <ul className={style.wrapperConferenceThisSpeakerInfo}>
                <li className={style.wrapperConferenceThisSpeakerInfoLi}>
                  <h3 className={style.titleThisInfo}>тема виступу:</h3>
                  <p className={style.textThisInfo}>{speaker.speakerTema}</p>
                </li>
                <li className={style.wrapperConferenceThisSpeakerInfoLi}>
                  <h3 className={style.titleThisInfo}>про спікера:</h3>
                  <ul className={style.wrapperTextThisInfo}>
                    {speaker.speakerAbout.map((about, idx) => (
                      <li className={style.textThisInfoLi} key={idx}>
                        {about}
                      </li>
                    ))}
                  </ul>
                </li>
                <li className={style.wrapperConferenceThisSpeakerInfoLi}>
                  <h3 className={style.titleThisInfo}>дата виступу:</h3>
                  <p className={style.textThisInfo}>27 - 28 жовтня 2024</p>
                </li>
              </ul>
            </SwiperSlide>
          ))}
        </Swiper>
        <button
          className={`${style.joinToConferenceBtn} redBtn`}
          onClick={() => {
            isLoggedIn ? setModalPrizeOpen(true) : setModalLogIn(true);
          }}
        >
          Відвідати конференцію
        </button>
      </div>
      <ChoiseTariffModal
        isOpen={modalPrizeIsOpen}
        onClose={() => setModalPrizeOpen(false)}
      ></ChoiseTariffModal>
      <LogIn
        isOpen={modalLogInIsOpen}
        onClose={() => setModalLogIn(false)}
      ></LogIn>
    </section>
  );
}
