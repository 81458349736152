// import About from "./About/About";
import { useEffect } from "react";
import Donation from "./Donation/Donation";
import Footer from "./HFM/Footer";
import Header from "./HFM/Header";
import Hero from "./Hero/Hero";
import Questions from "./Questions/Questions";
import Reviews from "./Reviews/Reviews";
import Speakers from "./Speakers/Speakers";
import Tariff from "./Tariff/Tariff";
import Upcoming from "./UpComingConf/Upcoming";
import { useDispatch, useSelector } from "react-redux";
import { signInOrLoginWithGoogle } from "../../redux/auth/operations";
import { selectIsLoggedIn } from "../../redux/auth/selectors";
import { useNavigate } from "react-router-dom";

export default function MainPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate(); // useNavigate вместо useHistory
  const isLoggedIn = useSelector(selectIsLoggedIn);

  useEffect(() => {
    // Извлекаем параметр code из URL
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get("code");

    if (code && isLoggedIn === false) {
      // Если есть code в URL, завершаем процесс Google OAuth
      dispatch(signInOrLoginWithGoogle(code))
        .unwrap()
        .then(() => {
          // Убираем code из URL после успешного логина
          navigate("/conference", { replace: true }); // Навигация без history.replace
        })
        .catch((error) => {
          console.error("Error during Google OAuth:", error);
        });
    }
  }, [dispatch, isLoggedIn, navigate]);
  return (
    <>
      <Header />
      <Hero />
      <Speakers />
      <Tariff />
      <Upcoming />
      <Reviews />
      <Questions />
      <Donation />
      <Footer />
    </>
  );
}
