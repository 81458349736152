import "./Modules.css";
import Modal from "react-modal";
import sprite from "../../../icons.svg";
// import { useNavigate } from "react-router-dom";
import axios from "axios";
import LogIn from "../../../Modals/LogIn/LogIn";
import { useState } from "react";
import { useSelector } from "react-redux";
import { selectIsLoggedIn } from "../../../../redux/auth/selectors";
// import ReactPixel from "react-facebook-pixel";

// eslint-disable-next-line react/prop-types
export const BurgerMenuModal = ({ isOpen, onClose, children }) => {
  return (
    <Modal
      isOpen={isOpen}
      overlayClassName={"modalBurgerMenu"}
      className={"modalContentBurgerMenu"}
      closeTimeoutMS={400}
      onRequestClose={() => onClose()}
      ariaHideApp={false}
    >
      <svg
        className="closeBurgerMenuBtn"
        width={32}
        height={32}
        onClick={onClose}
      >
        <use xlinkHref={`${sprite}#icon-close`}></use>
      </svg>
      {children}
    </Modal>
  );
};

// eslint-disable-next-line react/prop-types
export const MoreQuestions = ({ isOpen, onClose }) => {
  return (
    <Modal
      isOpen={isOpen}
      overlayClassName={"modalMoreQuestion"}
      className={"modalContentMoreQuestion"}
      closeTimeoutMS={400}
      onRequestClose={() => onClose}
      ariaHideApp={false}
    >
      <svg
        className="closeModalQuestions"
        width={32}
        height={32}
        onClick={onClose}
      >
        <use xlinkHref={`${sprite}#icon-close`}></use>
      </svg>
      <h2 className="titleMoreQuestion">
        За додатковими питання зверніться до нас за цими контактами:
      </h2>
      <a href="tel:+380936576711" className="modalContactUsLinks">
        + 38 (093) 657 67 11
      </a>
      <a href="tel:+48667994337" className="modalContactUsLinks">
        +48 667 994 337
      </a>
      <a href="mailto:women@psyconference.org" className="modalContactUsLinks">
        women@psyconference.org
      </a>
      <ul className="wrapperModalSocialLinks">
        <li>
          <a
            href="https://www.instagram.com/women.psyconference/"
            target="_blank"
          >
            <svg className="modalSocialIcons" width={40} height={40}>
              <use xlinkHref={`${sprite}#icon-instagram`}></use>
            </svg>
          </a>
        </li>
        <li>
          <a href="https://t.me/women_psyconference" target="_blank">
            <svg className="modalSocialIcons" width={40} height={40}>
              <use xlinkHref={`${sprite}#icon-telegram`}></use>
            </svg>
          </a>
        </li>
      </ul>
    </Modal>
  );
};

// eslint-disable-next-line react/prop-types
export const JoinZoomModalAlternative = ({ isOpen, onClose }) => {
  const connectToZoom = async (e) => {
    e.preventDefault();
    const email = e.target.elements.email.value.trim().toLowerCase();
    try {
      const response = await axios.patch(
        "/auth/mark-conference-connection-alternative",
        { email }
      );
      console.log(response);

      const isUserHaveAccessStatus = response.data.isUserHaveAccess;
      if (isUserHaveAccessStatus === true) {
        window.location.href =
          "https://us06web.zoom.us/j/87080882648?pwd=uvUSMNAMBNKMPFJ4dy0jGPjL2SIoUr.1";
      } else {
        alert(
          "Відмовлено у доступі. Якщо ви вважаєте що це помилка, зверніться до нашої підтримки для вирішення питання."
        );
      }
    } catch (error) {
      console.log("Error while trying to connect zoom", error);
      alert(
        "Сталася помилка під час спроби доєднатися до конференції, зверніться до нашої підтримки для вирішення питання."
      );
    }
  };
  return (
    <Modal
      isOpen={isOpen}
      overlayClassName={"modalJoinToZoom"}
      className={"modalContentJoinToZoom"}
      closeTimeoutMS={400}
      onRequestClose={onClose}
      ariaHideApp={false}
    >
      <svg className="closeModalJoinToZoom" onClick={onClose}>
        <use xlinkHref={`${sprite}#icon-close`}></use>
      </svg>
      <h2 className="titleModalJoinToZoom">
        Вітаємо! <br />
        <span className="spanModalJoinToZoom">
          надайте ваші данні для авторизації на конференцію
        </span>
      </h2>
      <form className="formJoinToZoom" onSubmit={connectToZoom}>
        <input
          className="inputJoinToZoom"
          type="text"
          placeholder="Email"
          name="email"
          required
        />
        <p className="warningJoinToZoom">
          Важливо написати пошту за якою ви реєструвались, інакше ви не
          потрапите на конференцію
        </p>
        <button className="joinToZoomButton" type="submit">
          Перейти в Zoom
        </button>
      </form>
    </Modal>
  );
};

export const JoinZoomModal = ({ isOpen, onClose, openModal }) => {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const closeLoginModal = () => {
    setIsLoginModalOpen(false);
  };

  const openLoginModal = () => {
    setIsLoginModalOpen(true);
  };

  const connectToZoom = async () => {
    try {
      const response = await axios.patch(
        "/userdata/mark-conference-connection",
        { conferenceName: "careConference" }
      );
      const isUserHaveAccessStatus = response.data.isUserHaveAccess;
      if (isUserHaveAccessStatus === true) {
        window.location.href =
          "https://us06web.zoom.us/j/87080882648?pwd=uvUSMNAMBNKMPFJ4dy0jGPjL2SIoUr.1";
      } else {
        alert(
          "Сталася помилка під час спроби доєднатися до конференції, відмовлено у доступі. Якщо ви вважаєте що це помилка, зверніться до нашої підтримки для вирішення питання."
        );
      }
    } catch (error) {
      console.log("Error while trying to connect zoom", error);
      alert(
        "Сталася помилка під час спроби доєднатися до конференції, зверніться до нашої підтримки для вирішення питання."
      );
    }
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        overlayClassName={"modalJoinToZoom"}
        className={"modalContentJoinToZoom"}
        closeTimeoutMS={400}
        onRequestClose={onClose}
        ariaHideApp={false}
      >
        <svg className="closeModalJoinToZoom" onClick={onClose}>
          <use xlinkHref={`${sprite}#icon-close`}></use>
        </svg>
        <h2 className="titleModalJoinToZoom">
          Вітаємо! <br />
          <span className="spanModalJoinToZoom">
            Натисніть на кнопку нижче для підключення до конференції
          </span>
        </h2>

        {isLoggedIn ? (
          <button
            className="joinToZoomButton"
            type="submit"
            onClick={connectToZoom}
          >
            Перейти в Zoom
          </button>
        ) : (
          <button
            className="joinToZoomButton"
            type="submit"
            onClick={openLoginModal}
          >
            Зарєструватись / Увійти
          </button>
        )}
        <p
          className="joinToZoomAlternativeText"
          onClick={() => openModal("joinZoomModalAlternative")}
        >
          Купували доступ до конференції не через сайт? <br />
          <span className="joinToZoomAlternativeTextDecorated">
            Перейти до альтернативного способу підключення
          </span>
        </p>
      </Modal>
      <LogIn isOpen={isLoginModalOpen} onClose={closeLoginModal} />
    </>
  );
};
