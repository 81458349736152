import AggreHero from "./AggreHero/AggreHero";
import AggreReviews from "./AggreReviews/AggreReviews";
import AggreSpeakers from "./AggreSpeakers/AggreSpeakers";
import AggreTariff from "./AggreTariff/AggreTariff";
import AggreTeez from "./AggreTeez/AggreTeez";
import Footer from "../HFM/Footer";
import Header from "../HFM/Header";
import "../../../index.css";

export default function FirstConferencePage() {
  return (
    <>
      <Header />
      <AggreHero />
      <AggreSpeakers />
      <AggreTariff />
      <AggreTeez />
      <AggreReviews />
      <Footer />
    </>
  );
}
