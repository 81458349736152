import { Swiper, SwiperSlide } from "swiper/react";
import styles from "./Reviews.module.css";
import { useEffect, useState, useRef } from "react";
import "swiper/css/navigation";
import sprite from "../../icons.svg";
import { Navigation } from "swiper/modules";

export default function Reviews() {
  const swiperRef = useRef(null);

  const [slidesPerView, setSlidesPerView] = useState(1);
  useEffect(() => {
    function handleResize() {
      const screenWidth = window.innerWidth;
      if (screenWidth <= 445) {
        setSlidesPerView(1);
      } else {
        setSlidesPerView(2);
      }
    }

    // Викликати handleResize при завантаженні сторінки
    handleResize();

    window.addEventListener("resize", handleResize);

    // Прибрати прослуховувач подій при розмонтовуванні компонента
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const reviewsJSON = [
    {
      name: "Nataliya",
      comment:
        "Твій перший ретрит - це найкраще що зі мною сталось. Жінки, зробіть собі подарунок - знайдіть час та кошти на продукт, який Настя зробила. Це фантастика! Кількість практик і швидкість змін вас здивують",
    },
    {
      name: "Angelina",
      comment:
        "Вітаю! Хочу подякувати за Канни і за ці 9 днів іншої реальності в просторі з жінками. Місце, ораганізація, дводенки, виїзд на яхту - все було супер. Дякую Анастасії за ретрит і турботу. Я поїхала з новою Читати далі...",
    },
    {
      name: "Оксана",
      comment:
        "Складно було коротко все описати, але дійсно такий кайф згадувати цю зустріч. Тепер в планах точно є відвідати ще раз подібне. Настя створила дуже класний проект і її ідеї просто. Дякую вам обом!",
    },
    {
      name: "Анастасія",
      comment:
        "Що то за диво двіж «ретрит з Настюшоу»? То трошки бдсм але в такому коханні та з такою турботою - що навіть релігійніскажуть «з Богом». То дійсно дивовижний час відкриття внутрішніх станів, але, що найважливіше, саме Читати далі...",
    },
    {
      name: "Наталя",
      comment:
        "Всі бачили, надіюсь, що ми з Євою попали до Насті на ретрит Жінки. І що вам сказати... Настя - цілий космос! Одна велика незвідана планета. Таку кількість практик, в одному  Читати далі...",
    },
  ];
  return (
    <section className={styles.sectionReviewsRetrite} id="reviews">
      <div className="container">
        <h2 className={styles.titleReviews}> відгуки</h2>
        <div className={styles.mySwiperReviewsNavigation}>
          <div
            className={styles.swiperButtonReviewsPrev}
            onClick={() => swiperRef.current.swiper.slidePrev()}
          >
            <svg
              className={styles.swiperButtonReviewsPrevBorder}
              width={25}
              height={25}
              fill="white"
            >
              <use xlinkHref={`${sprite}#icon-doubleLeftArrow`}></use>
            </svg>
          </div>
          <div
            className={styles.swiperButtonReviewsNext}
            onClick={() => swiperRef.current.swiper.slideNext()}
          >
            <svg
              className={styles.swiperButtonMainNextBorder}
              width={25}
              height={25}
              fill="white"
            >
              <use xlinkHref={`${sprite}#icon-doubleRightArrow`}></use>
            </svg>
          </div>
        </div>
        <Swiper
          ref={swiperRef}
          slidesPerView={slidesPerView}
          spaceBetween={40}
          navigation={{
            prevEl: `.${styles.swiperButtonPrevReviews}`,
            nextEl: `.${styles.swiperButtonNextReviews}`,
                  }}
                  autoHeight={true}
          modules={[Navigation]}
          className={styles.mySwiperReviewsRetrite}
        >
          {reviewsJSON.map((rev, index) => (
            <SwiperSlide key={index} virtualIndex={index}>
              <h3 className={styles.reviewerName}>{rev.name}</h3>
              <p className={styles.reviewerComment}>{rev.comment}</p>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
}
