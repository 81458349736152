import styles from './FlirtTariff.module.css'
import sprite from "../../../icons.svg";
import ChoiseFlirtVideo from "../Modals/FlirtVideoChoise";
import { useState } from "react";

export default function FlirtTariff() {
  const [modalPrizeIsOpen, setModalPrizeOpen] = useState(false);

    return (
      <section className={styles.aggreTariffSection} id="tariffSection">
        <h2 className={styles.titleAggreTariffSection}>/Тарифи</h2>
        <ul className={styles.wrapperBtnsForBuyFC}>
          <li>
            <p className={styles.aggrePriceForTariff}>49€</p>
            <p className={styles.aggreInfoForPrice}>
              <svg
                className={styles.aggreSpiralForPriceSVG}
                width={27}
                height={27}
              >
                <use xlinkHref={`${sprite}#icon-spiralSVG`}></use>
              </svg>
              Відеозапис двох виступів <br /> з конференції на вибір
            </p>
            <p className={styles.aggreInfoForPrice}>
              <svg
                className={styles.aggreSpiralForPriceSVG}
                width={27}
                height={27}
              >
                <use xlinkHref={`${sprite}#icon-spiralSVG`}></use>
              </svg>
              Доступ на рік
            </p>
            <button
              className={`${styles.aggreWhiteBtnTariff} whiteBtn`}
              onClick={() => setModalPrizeOpen(true)}
            >
              Придбати
            </button>
          </li>
          <li>
            <p className={styles.aggrePriceForTariff}>159€</p>
            <p className={styles.aggreInfoForPrice}>
              <svg
                className={styles.aggreSpiralForPriceSVG}
                width={27}
                height={27}
              >
                <use xlinkHref={`${sprite}#icon-spiralSVG`}></use>
              </svg>
              Відеозапис всіх <br /> виступів конференції
            </p>
            <p className={styles.aggreInfoForPrice}>
              <svg
                className={styles.aggreSpiralForPriceSVG}
                width={27}
                height={27}
              >
                <use xlinkHref={`${sprite}#icon-spiralSVG`}></use>
              </svg>
              Доступ на рік
            </p>
            <button
              className={`${styles.aggreWhiteBtnTariff} whiteBtn`}
              onClick={() => setModalPrizeOpen(true)}
            >
              Придбати
            </button>
          </li>
        </ul>
        <ChoiseFlirtVideo
          isOpen={modalPrizeIsOpen}
          onClose={() => setModalPrizeOpen(false)}
        ></ChoiseFlirtVideo>
      </section>
    );
}