import { useState, useEffect } from "react";
import Modal from "react-modal";
import sprite from "../../../icons.svg";
import "../../AggreConference/ModulesAggre.css";
import axios from "axios";
import { useSelector } from "react-redux";
import { selectIsLoggedIn, selectUser } from "../../../../redux/auth/selectors";

export default function ChoiseProhibitionVideo({ isOpen, onClose }) {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const userData = useSelector(selectUser);

  const [selectedTariffs, setSelectedTariffs] = useState([]);
  const [videoProhibition, setVideoProhibition] = useState([]); // массив для выбранных видео
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    additionalInfo: "",
  });

  // Если человек залогинен, его данные вписываются автоматически
  useEffect(() => {
    if (isLoggedIn && userData) {
      setFormData({
        firstName: userData.firstName || "",
        lastName: userData.lastName || "",
        email: userData.email || "",
      });
      setSelectedTariffs([]);
      setVideoProhibition([]);
    }
  }, [isLoggedIn, userData]);

  const tariffPrices = {
    parmsForTwoVideo: 49,
    parmsForAllVideo: 159,
  };

  const allProhibitionVideos = [
    "MatushevskaProhibition",
    "BaevaProhibition",
    "MarchekProhibition",
    "PavluchokProhibition",
    "ZimovinProhibition",
    "IslamhulovaProhibition",
    "DerhousovaProhibition",
    "HayvoronskaProhibition",
    "DiveevaProhibition",
    "ZadoroznaProhibition",
    "ZoriyProhibition",
    "HorobecProhibition",
  ];

  const handleCheckboxChange = (e) => {
    const { id, checked } = e.target;

    setSelectedTariffs((prevSelected) => {
      if (id === "parmsForTwoVideo") {
        if (checked) {
          // Если выбрали тариф на два видео, убираем тариф на все видео
          document.getElementById("parmsForAllVideo").checked = false;
          return ["parmsForTwoVideo"];
        } else {
          return prevSelected.filter((tariff) => tariff !== id);
        }
      }

      if (id === "parmsForAllVideo") {
        if (checked) {
          // Если выбрали тариф на все видео, убираем тариф на два видео и добавляем все видео в список
          document.getElementById("parmsForTwoVideo").checked = false;
          setVideoProhibition(allProhibitionVideos); // устанавливаем все видео
          return ["parmsForAllVideo"];
        } else {
          setVideoProhibition([]); // очищаем список видео при отмене тарифа на все видео
          return prevSelected.filter((tariff) => tariff !== id);
        }
      }

      return checked
        ? [...prevSelected, id]
        : prevSelected.filter((tariff) => tariff !== id);
    });

    // Очищаем выбор видео, если убираем тариф "parmsForTwoVideo"
    if (id === "parmsForTwoVideo" && !checked) {
      setVideoProhibition([]);
    }
  };

  const handleVideoSelection = (e) => {
    const { id, checked } = e.target;

    setVideoProhibition((prevSelected) => {
      // Если добавляем видео и выбрано меньше двух
      if (checked && prevSelected.length < 2) {
        return [...prevSelected, id];
      }

      // Убираем выбранное видео
      return prevSelected.filter((videoId) => videoId !== id);
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const calculateTotalPrice = () => {
    return selectedTariffs.reduce(
      (total, tariffId) => total + tariffPrices[tariffId],
      0
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.firstName || !formData.email || !formData.additionalInfo) {
      alert(
        "Будь ласка, заповніть обов'язкові поля: Ім'я, Прізвище, Email і Додаткова інформація."
      );
      return;
    }

    if (selectedTariffs.length === 0) {
      alert("Будь ласка, оберіть хоча б один тариф.");
      return;
    }

    if (
      selectedTariffs.includes("parmsForTwoVideo") &&
      videoProhibition.length !== 2
    ) {
      alert("Будь ласка, оберіть дві лекції для вибраного тарифу.");
      return;
    }

    try {
      const conferenceName = "prohibitionConference";
      const response = await axios.post("https://women.place/create-payment", {
        amount: calculateTotalPrice() * 100,
        currency: 978,
        redirectUrl: "https://women.place/thxforpayment",
        email: formData.email,
        selectedTariffs,
        video: videoProhibition,
        conferenceName,
      });

      // Переход на страницу оплаты
      window.location.href = response.data.pageUrl;
    } catch (error) {
      console.error(
        "Помилка під час створення платежу:",
        error.response ? error.response.data : error.message
      );
      alert(
        `Помилка під час створення платежу: ${
          error.response ? JSON.stringify(error.response.data) : error.message
        }`
      );
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      overlayClassName={"modalChoiseAggreVideo"}
      className={"modalContentChoiseAggreVideo"}
      closeTimeoutMS={400}
      ariaHideApp={false}
    >
      <svg
        className="closeModalChoiseAggreVideo"
        onClick={onClose}
        width={50}
        height={50}
      >
        <use xlinkHref={`${sprite}#icon-close`}></use>
      </svg>
      <h2 className="titleChoiseAggreVideo">1. оберіть тариф</h2>
      <form className="formChoiseAggreVideo">
        <div className="wrapperTariffCheck">
          <label
            htmlFor="parmsForTwoVideo"
            className="labelCheckBoxVisitConference"
          >
            Відеозапис двох виступів з конференції на вибір
            <input
              id="parmsForTwoVideo"
              type="checkbox"
              className="custom-checkbox"
              onChange={handleCheckboxChange}
            />
            <div className="checkbox__checkmark"></div>
            <div className="checkbox__body"></div>
          </label>
        </div>
        {selectedTariffs.includes("parmsForTwoVideo") && (
          <div className="wrapperChoisetwoVideoFromAll">
            <h3 className="titleChoisetwoVideoFromAll">оберіть дві лекції:</h3>
            {[
              {
                id: "MatushevskaProhibition",
                title:
                  "Кризи жіночої ідентичності як природнє порушення кордонів",
                speaker: "Анастасія-Олімпія Матюшевська",
              },
              {
                id: "BaevaProhibition",
                title: "Смак заборон",
                speaker: "Олена Баєва",
              },
              {
                id: "MarchekProhibition",
                title:
                  "«На захисті беззахисного». Вплив раннього травматичного досвіду на формування особистих кордонів.",
                speaker: "Світлана Марчек",
              },
              {
                id: "PavluchokProhibition",
                title:
                  "Ідентичність українок у сучасному світі після вторгнення",
                speaker: "Наталія Павлючок",
              },
              {
                id: "ZimovinProhibition",
                title: "Від дільожки до обміну: жінки та чоловіки НА МЕЖІ",
                speaker: "Олексій Зимовін",
              },
              {
                id: "IslamhulovaProhibition",
                title: "Кожній жінці потрібні її власні гроші",
                speaker: "Аделя Ісламгулова",
              },
              {
                id: "DerhousovaProhibition",
                title: "Як досягають бажаного без боротьби, стресу та суєти.",
                speaker: "Саша Дергоусова",
              },
              {
                id: "HayvoronskaProhibition",
                title: "Безпека у інтимних стосунках",
                speaker: "Юлія Гайворонская",
              },
              {
                id: "DiveevaProhibition",
                title:
                  "ВИХІД - психологічний розбір діагнозів та складних ситуацій учасників",
                speaker: "Ольга Дівєєва",
              },
              {
                id: "ZadoroznaProhibition",
                title: "Особисті кордони або чому з вами можно чинити погано!",
                speaker: "Веронiка Задорожна",
              },
              {
                id: "ZoriyProhibition",
                title:
                  "Як перепрограмувати свій мозок на досягнення своїх бажаних цілей",
                speaker: "Алла Зорій",
              },
              {
                id: "HorobecProhibition",
                title:
                  "Жіночі кордони та сила мережування: як порушувати правила, залишаючись вірною собі",
                speaker: "Марися Горобець",
              },
            ].map((video) => (
              <div key={video.id} className="wrapperTariffCheck">
                <label
                  htmlFor={video.id}
                  className="labelCheckBoxVisitConferenceAggre"
                >
                  <p>
                    {video.title} - <span>{video.speaker}</span>
                  </p>
                  <input
                    id={video.id}
                    type="checkbox"
                    className="custom-checkbox"
                    onChange={handleVideoSelection}
                    checked={videoProhibition.includes(video.id)} // отмечаем чекбокс, если видео выбрано
                    disabled={
                      videoProhibition.length === 2 &&
                      !videoProhibition.includes(video.id)
                    }
                  />
                  <div className="checkbox__checkmark"></div>
                  <div className="checkbox__body"></div>
                </label>
              </div>
            ))}
          </div>
        )}
        <div className="wrapperTariffCheck">
          <label
            htmlFor="parmsForAllVideo"
            className="labelCheckBoxVisitConference"
          >
            Відеозапис всіх виступів конференції
            <input
              id="parmsForAllVideo"
              type="checkbox"
              className="custom-checkbox"
              onChange={handleCheckboxChange}
            />
            <div className="checkbox__checkmark"></div>
            <div className="checkbox__body"></div>
          </label>
        </div>
      </form>
      <p className="textFormPriceForTariff">
        Вартість: {calculateTotalPrice()} EUR
      </p>
      <h2 className="titleGetUserData">2. надайте ваші данні:</h2>
      <form className="formForUserData" id="personForm" onSubmit={handleSubmit}>
        <div className="form-group">
          <label className="labelForUserData" htmlFor="firstName">
            <input
              className="inputUserDate"
              type="text"
              id="firstName"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              required
              disabled={isLoggedIn}
            />
            <span className="spanInputPlaceholder">Імʼя</span>
          </label>
        </div>
        <div className="form-group">
          <label className="labelForUserData" htmlFor="lastName">
            <input
              className="inputUserDate"
              type="text"
              id="lastName"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              disabled={isLoggedIn}
            />
            <span className="spanInputPlaceholder">Прізвище</span>
          </label>
        </div>
        <div className="form-group">
          <label className="labelForUserData" htmlFor="email">
            <input
              className="inputUserDate"
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              disabled={isLoggedIn}
            />
            <span className="spanInputPlaceholder">Email</span>
          </label>
        </div>
        <div className="form-group">
          <label
            className="labelForUserData labelForUserDataBorderNone"
            htmlFor="additionalInfo"
          >
            <textarea
              className="textareaUserData"
              id="additionalInfo"
              name="additionalInfo"
              value={formData.additionalInfo}
              onChange={handleChange}
              required
              placeholder="Додаткова інформація"
            />
          </label>
        </div>
        <div className="form-group">
          <button className="redBtn redBtnForAggreVideo" type="submit">
            Перейти до оплати
          </button>
        </div>
      </form>
    </Modal>
  );
}
