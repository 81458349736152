import styles from "./VideoForChoise.module.css";
import sprite from "../../../../icons.svg";
import Modal from "react-modal";
import axios from "axios";
import { useState } from "react";

export default function VideoForChoise({ isOpen, onClose, conferenceName }) {
  const speakerVideoData = {
    agreeConference: [
      { speaker: "Ірина Ватрич", id: "VatrichAggre" },
      { speaker: "Тетяна Назаренко", id: "NazarenkoAggre" },
      { speaker: "Ольга Дівєєва", id: "DiveevaAggre" },
      { speaker: "Анастасія-Олімпія Матюшевська", id: "MatushevskaAggre" },
      { speaker: "Наталія Ішкова", id: "IshkovaAggre" },
      { speaker: "Вікторія Пруднікова", id: "PrudnikovaAggre" },
      { speaker: "Світлана Марчек", id: "MarchekAggre" },
      { speaker: "Лєна Адамова", id: "AdamovaAggre" },
      { speaker: "Наталія Павлючок", id: "PavluchokAggre" },
      { speaker: "Василіса Левченко", id: "LevchenkoAggre" },
      { speaker: "Олексій Зимовін", id: "ZimovinAggre" },
      { speaker: "Олена Баєва", id: "BaevaAggre" },
    ],
    flirtConference: [
      { speaker: "Леся Матвєєва", id: "MatveevaFlirt" },
      { speaker: "Наталія Павлючок", id: "PavluchokFlirt" },
      { speaker: "Юлія Гайворонська", id: "HayvoronskaFlirt" },
      { speaker: "Олена Баєва", id: "BaevaFlirt" },
      { speaker: "Світлана Марчек", id: "MarchekFlirt" },
      { speaker: "Ольга Сологуб", id: "SolohubFlirt" },
      { speaker: "Лєна Адамова", id: "AdamovaFlirt" },
      { speaker: "Вікторія Пруднікова", id: "PrudnikovaFlirt" },
      { speaker: "Костянтин Несвіт", id: "NesvitFlirt" },
      { speaker: "Анастасія Матюшевська", id: "MatushevskaFlirt" },
      { speaker: "Юлія Козачкова", id: "KozachkovaFlirt" },
    ],
    prohibitionConference: [
      { speaker: "Анастасія Матюшевська", id: "MatushevskaProhibition" },
      { speaker: " Олена Баєва", id: "BaevaProhibition" },
      { speaker: "Світлана Марчек", id: "MarchekProhibition" },
      { speaker: "Наталія Павлючок", id: "PavluchokProhibition" },
      { speaker: "Олексій Зимовін", id: "ZimovinProhibition" },
      { speaker: "Аделя Ісламгулова", id: "IslamhulovaProhibition" },
      { speaker: "Саша Дергоусова", id: "DerhousovaProhibition" },
      { speaker: "Юлія Гайворонська", id: "HayvoronskaProhibition" },
      { speaker: "Ольга Дівєєва", id: "DiveevaProhibition" },
      { speaker: "Вероніка Задорожна", id: "ZadoroznaProhibition" },
      { speaker: "Алла Зорій", id: "ZoriyProhibition" },
      { speaker: "Марися Горобець", id: "HorobecProhibition" },
    ],
  };

  const [selectedSpeakers, setSelectedSpeakers] = useState([]);

  const handleCheckboxChange = (id) => {
    if (selectedSpeakers.includes(id)) {
      setSelectedSpeakers(
        selectedSpeakers.filter((speakerID) => speakerID !== id)
      );
    } else if (selectedSpeakers.length < 2) {
      setSelectedSpeakers([...selectedSpeakers, id]);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Выбранные спикеры:", selectedSpeakers);
    try {
      await axios.post("https://women.place/userdata/update-paid-videos", {
        conferenceName,
        selectedSpeakers,
      });
    } catch (error) {
      console.log(e);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      overlayClassName={styles.ChoiseVideoModal}
      className={styles.contentChoiseVideoModal}
      closeTimeoutMS={400}
      onRequestClose={onClose}
      ariaHideApp={false}
    >
      <svg
        className={styles.closeChoiseVideo}
        onClick={onClose}
        width={24}
        height={24}
      >
        <use xlinkHref={`${sprite}#icon-close`}></use>
      </svg>
      <form
        className={styles.formForChoiseVideoAfterConference}
        onSubmit={handleSubmit}
      >
        {speakerVideoData[conferenceName] &&
          speakerVideoData[conferenceName].map((video) => (
            <div key={video.id} className={styles.wrapperVideoChoise}>
              <label htmlFor={video.id} className={styles.labelChoiseVideoAC}>
                <p className={styles.labelText}>{video.speaker}</p>
                <input
                  id={video.id}
                  type="checkbox"
                  className={styles.customCheckBox}
                  onChange={() => handleCheckboxChange(video.id)}
                  checked={selectedSpeakers.includes(video.id)}
                  disabled={
                    selectedSpeakers.length === 2 &&
                    !selectedSpeakers.includes(video.id)
                  }
                />
                <div className={styles.checkbox__checkmark}></div>
              </label>
            </div>
          ))}
        <button
          className={styles.myChoiseBtn}
          type="submit"
          disabled={selectedSpeakers.length !== 2}
          onClick={onClose}
        >
          Обрати
        </button>
      </form>
    </Modal>
  );
}
