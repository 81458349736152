import sprite from "../../icons.svg";
import styles from "./MyConference.module.css";
// import axios from "axios";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectUserData } from "../../../redux/userData/selectors";
import { getUserData } from "../../../redux/userData/operations";
import VideoModal from "./ModalsForVideo/VideoModal/VideoModal";
import VideoForChoise from "./ModalsForVideo/VideoForChoise/VideoForChoise";

export default function MyConference() {
  const [videoModalIsOpen, setVideoModalIsOpen] = useState(false);
  const [videoForChoiseIsOpen, setVideoForChoiseIsOpen] = useState(false);
  const [conferenceName, setConferenceName] = useState(""); // сохраняем выбранную конференцию

  const userData = useSelector(selectUserData);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserData()); // Загружаем данные пользователя
  }, [dispatch]);

  // Получаем статус оплаты по конференциям
  const paidStatusOnConferenceAggre =
    userData?.conferences?.agreeConference?.paidConferenceTariffs || [];
  const paidStatusOnConferenceFlirt =
    userData?.conferences?.flirtConference?.paidConferenceTariffs || [];
  const paidStatusOnConferenceProhibition =
    userData?.conferences?.prohibitionConference?.paidConferenceTariffs || [];

  // Проверяем доступные видео
  const myAggreVideo = userData?.conferences?.agreeConference?.paidVideos || [];
  const myFlirtVideo = userData?.conferences?.flirtConference?.paidVideos || [];
  const myProhibitionVideo =
    userData?.conferences?.prohibitionConference?.paidVideos || [];

  // Статус наличия тарифа с 2 видео
  const TwoVideosStatusAggre = paidStatusOnConferenceAggre.includes("parmsTwo");
  const TwoVideosStatusFlirt = paidStatusOnConferenceFlirt.includes("parmsTwo");
  const TwoVideosStatusProhibition =
    paidStatusOnConferenceProhibition.includes("parmsTwo");

  // Проверка, если нет оплаченных конференций
  const noPaidConferences =
    !TwoVideosStatusAggre &&
    myAggreVideo.length === 0 &&
    !TwoVideosStatusFlirt &&
    myFlirtVideo.length === 0 &&
    !TwoVideosStatusProhibition &&
    myProhibitionVideo.length === 0;

  // const handleSubmit = async (conference) => {
  //   setConferenceName(conference); // сохраняем название конференции
  //   console.log("Выбранная конференция:", conference);
  //   try {
  //     await axios.post(
  //       "/userdata/update-paid-videos",
  //       {
  //         // conferenceName: conference, // отправляем выбранную конференцию
  //       }
  //     );
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  return (
    <section className={styles.myConferenceSection}>
      <div className="container">
        <h2 className={styles.titleMyConferenceVideo}>
          <svg className={styles.MyVideoSVG} width={20} height={20}>
            <use xlinkHref={`${sprite}#icon-play`}></use>
          </svg>
          Мої записи
        </h2>
        {noPaidConferences && (
          <p className={styles.textWithOutPaidVideo}>
            У вас поки немає придбаних записів
          </p>
        )}
        <ul className={styles.wrapperAllConference}>
          {/* ПРО АГРЕСІЮ */}
          {(TwoVideosStatusAggre || myAggreVideo.length > 0) && (
            <li>
              <h3 className={styles.titleThisConference}>
                ПРО АГРЕСІЮ: жінок, до жінок, біля жінок
              </h3>
              <p className={styles.dateThisConference}>
                30 червня - 1 липня 2024
              </p>
              <button
                type="button"
                className={styles.thisConferenceBtn}
                onClick={() => {
                  setConferenceName("agreeConference");
                  myAggreVideo.length > 0
                    ? setVideoModalIsOpen(true)
                    : setVideoForChoiseIsOpen(true);
                }}
              >
                {myAggreVideo.length > 0 ? "Переглянути" : "Обрати"}
              </button>
            </li>
          )}

          {/* ФЛІРТ */}
          {(TwoVideosStatusFlirt || myFlirtVideo.length > 0) && (
            <li>
              <h3 className={styles.titleThisConference}>
                флірт, кокетство, любовна гра
              </h3>
              <p className={styles.dateThisConference}>10 - 11 вересня 2024</p>
              <button
                type="button"
                className={styles.thisConferenceBtn}
                onClick={() => {
                  setConferenceName("flirtConference");
                  myFlirtVideo.length > 0 ? setVideoModalIsOpen(true) : setVideoForChoiseIsOpen(true)
                }}
              >
                {myFlirtVideo.length > 0 ? "Переглянути" : "Обрати"}
              </button>
            </li>
          )}

          {/* ЗАБОРОНИ */}
          {(TwoVideosStatusProhibition || myProhibitionVideo.length > 0) && (
            <li>
              <h3 className={styles.titleThisConference}>
                КОРДОНИ ТА МЕЖІ <br />
                Заборони та їх порушення
              </h3>
              <p className={styles.dateThisConference}>10 - 11 вересня 2024</p>
              <button
                type="button"
                className={styles.thisConferenceBtn}
                onClick={() => {
                  setConferenceName("prohibitionConference");
                  myProhibitionVideo.length > 0 ? setVideoModalIsOpen(true) : setVideoForChoiseIsOpen(true)
                }}
              >
                {myProhibitionVideo.length > 0 ? "Переглянути" : "Обрати"}
              </button>
            </li>
          )}
        </ul>
      </div>
      <VideoModal
        isOpen={videoModalIsOpen}
        onClose={() => setVideoModalIsOpen(false)}
        conferenceName={conferenceName} // Передаем название конференции
      />
      <VideoForChoise
        isOpen={videoForChoiseIsOpen}
        onClose={() => setVideoForChoiseIsOpen(false)}
        conferenceName={conferenceName} // Передаем название конференции
      />
    </section>
  );
}
