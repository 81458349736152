import styles from "./Profile.module.css";
import { Link } from "react-router-dom";
import sprite from "../icons.svg";
import LogIn from "../Modals/LogIn/LogIn";
import ProfileModal from "../Modals/ProfileModal/ProfileModal";
import { selectIsLoggedIn } from "../../redux/auth/selectors";
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import FooterProfile from "./FooterProfile/FotterProfile";

import MyConference from "../UsersProfiles/MyConference/MyConference";
import Support from "../UsersProfiles/Support/Support";
import Settings from "../UsersProfiles/Settings/Settings";
import Notification from "../UsersProfiles/Notifications/Notifications";
import MyProfile from "../UsersProfiles/MyProfile/MyProfile";
import OurConference from "../UsersProfiles/OurConference/OurConference";
import { logOut } from "../../redux/auth/operations";

export default function ProfilePage() {
  const [modalLogInIsOpen, setModalLogIn] = useState(false);
  const [modalProfileIsOpen, setModalProfile] = useState(false);
  const [selectedSection, setSelectedSection] = useState("Profile");

  const isLoggedIn = useSelector(selectIsLoggedIn);
  const dispatch = useDispatch();

  const renderSection = () => {
    switch (selectedSection) {
      case "Profile":
        return <MyProfile />;
      case "MyConference":
        return <MyConference />;
      case "Support":
        return <Support />;
      case "Settings":
        return <Settings />;
      case "Notification":
        return <Notification />;
      case "OurConference":
        return <OurConference />;
      default:
        return <MyProfile />;
    }
  };

  return (
    <div className={styles.profileWhiteBackground}>
      <header className={styles.headerProfile}>
        <div className="container">
          <Link to="/conference">
            <svg className={styles.mainLogoIMG} width={236} height={70}>
              <use xlinkHref={`${sprite}#icon-womenPlace`}></use>
            </svg>
          </Link>
          <button
            className={styles.LogInBtn}
            onClick={() =>
              isLoggedIn ? setModalProfile(true) : setModalLogIn(true)
            }
          >
            <svg className={styles.logInBtnSVG} width={24} height={24}>
              <use xlinkHref={`${sprite}#icon-user`}></use>
            </svg>
            <span className={styles.LogInBtnText}>
              {isLoggedIn ? "мій профіль" : "вхід"}
            </span>
          </button>
          <svg
            className={styles.burgerMenuProfile}
            width={50}
            height={36}
            onClick={() => {}}
          >
            <use xlinkHref={`${sprite}#icon-burger-menu`}></use>
          </svg>
        </div>
      </header>
      <div className={styles.profileContent}>
        <nav className={styles.profileDescNavigation}>
          <ul className={styles.wrapperProfileNavigation}>
            <li
              className={`${styles.navigation} ${
                selectedSection === "Profile" ? styles.navigationActive : ""
              } ${styles.navigationProfile}`}
              onClick={() => setSelectedSection("Profile")}
            >
              <Link to="/profile">
                <svg className={styles.navigationSVG} width={24} height={24}>
                  <use xlinkHref={`${sprite}#icon-user`}></use>
                </svg>
                Мій профіль
              </Link>
            </li>
            <li
              className={`${styles.navigation} ${
                selectedSection === "MyConference"
                  ? styles.navigationActive
                  : ""
              }`}
              onClick={() => setSelectedSection("MyConference")}
            >
              <Link to="/profile">
                <svg className={styles.navigationSVG} width={24} height={24}>
                  <use xlinkHref={`${sprite}#icon-playProfile`}></use>
                </svg>
                Мої записи
              </Link>
            </li>
            <li
              className={`${styles.navigation} ${
                selectedSection === "OurConference"
                  ? styles.navigationActive
                  : ""
              }`}
              onClick={() => setSelectedSection("OurConference")}
            >
              <Link to="/profile">
                <svg className={styles.navigationSVG} width={24} height={24}>
                  <use xlinkHref={`${sprite}#icon-client`}></use>
                </svg>
                Наші конференції
              </Link>
            </li>
            <li
              className={`${styles.navigation} ${
                selectedSection === "Notification"
                  ? styles.navigationActive
                  : ""
              }`}
              onClick={() => setSelectedSection("Notification")}
            >
              <Link to="/profile">
                <svg className={styles.navigationSVG} width={24} height={24}>
                  <use xlinkHref={`${sprite}#icon-notificationsProfile`}></use>
                </svg>
                Сповіщення
              </Link>
            </li>
            <li
              className={`${styles.navigation} ${
                selectedSection === "Settings" ? styles.navigationActive : ""
              }`}
              onClick={() => setSelectedSection("Settings")}
            >
              <Link to="/profile">
                <svg className={styles.navigationSVG} width={24} height={24}>
                  <use xlinkHref={`${sprite}#icon-settingsProfile`}></use>
                </svg>
                Налаштування
              </Link>
            </li>
            <li
              className={`${styles.navigation} ${
                selectedSection === "Support" ? styles.navigationActive : ""
              }`}
              onClick={() => setSelectedSection("Support")}
            >
              <Link to="/profile">
                <svg className={styles.navigationSVG} width={24} height={24}>
                  <use xlinkHref={`${sprite}#icon-supportProfile`}></use>
                </svg>
                Підтримка
              </Link>
            </li>
          </ul>
          <Link to="/conference" className={styles.logOutBtn}>
            <button
              className={styles.logOutBtn}
              onClick={() => {
                dispatch(logOut());
              }}
            >
              <svg className={styles.logOutSVG} width={24} height={24}>
                <use xlinkHref={`${sprite}#icon-logOutSVG`}></use>
              </svg>
              Вийти
            </button>
          </Link>
        </nav>
        <div className={styles.wrapperRenderSection}>{renderSection()}</div>
      </div>
      <FooterProfile />
      <LogIn
        isOpen={modalLogInIsOpen}
        onClose={() => setModalLogIn(false)}
        mode="login"
      />
      <ProfileModal
        isOpen={modalProfileIsOpen}
        onClose={() => setModalProfile(false)}
        setSelectedSection={setSelectedSection} // Передаем setSelectedSection
      />
    </div>
  );
}
