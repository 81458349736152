import styles from "./ResetPassPage.module.css";
import axios from "axios";

export default function ResetPassPage() {
  const searchParams = new URLSearchParams(window.location.search);
  const handleSubmit = async (e) => {
    e.preventDefault();

    const password = e.target.elements.newPassword.value;

    const token = searchParams.get("token");
    console.log("====================================");
    console.log(token);
    console.log("====================================");
    try {
      await axios.post("auth/reset-pwd", {
        token,
        password,
      });
    } catch (error) {
      alert("Щось пішло не так, спробуйте, будь ласка, пізніше!");
      return;
    }
    alert("Пароль було успішно відновлено, ви можете закрити це вікно!");
  };
  return (
    <section className={styles.ResetPassPageSection}>
      <div className="container">
        <h2 className={styles.resetPassTitle}>Новий пароль</h2>
        <form onSubmit={(e) => handleSubmit(e)}>
          <div className={styles.formGroupLogIn}>
            <label htmlFor="newPassword" className={styles.logInLabel}>
              <input
                className={styles.inputUserEmailLogIn}
                type="text"
                id="newPassword"
                name="newPassword"
                required
                placeholder="Пароль"
              />
            </label>
          </div>

          <button type="submit" className="redBtn">
            Скинути пароль
          </button>
        </form>
      </div>
    </section>
  );
}
