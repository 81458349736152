import styles from "./About.module.css";
import firstImage from "./firstImage.webp";
import secondImage from "./secondImage.webp";
import thirdImage from "./thirdImage.webp";
import beachImage from "./beachImage.webp";
import secondBeachImage from "./secondBeachImage.webp";

export const About = () => {
  return (
    <section className={styles.section} id="about">
      <div className={styles.container}>
        <h2 className={styles.sectionTitle}>про інтенсив</h2>
        <p className={styles.mainText}>
          9 днів у колі жінок про Психологію
          <span className={styles.mainTextContent}>
            <img
              src={firstImage}
              className={styles.mainTextImg}
              alt="Decorative image"
            />
          </span>
          та Сексологію Жінки, про внутрішню красу, прояви, середовище,
          ВІТАЛЬНІСТЬ
          <img
            src={secondImage}
            className={styles.mainTextImg}
            alt="Decorative image"
          />
          справжність, сексуальність, СИЛУ, вразливість та обмеження,{" "}
          <br className={styles.onlyOnMobile} />
          різність та схожість
          <img
            src={thirdImage}
            className={styles.mainTextImg}
            alt="Decorative image"
          />
        </p>
        <div className={styles.introducingContainer}>
          <img
            src={beachImage}
            className={styles.introducingImage}
            alt="Women sitting on the beach"
          />
          <p className={styles.introducingText}>
            Я, Анастасія-Олімпія Матюшевська, і перш за все{" "}
            <span className={styles.highlightedText}>я-жiнка!</span>
            <span className={styles.imageDecorationLine}></span>
          </p>
        </div>
        <div className={`${styles.introducingContainer} ${styles.reversed}`}>
          <img
            src={secondBeachImage}
            className={styles.introducingImage}
            alt="Women sitting on the beach"
          />
          <p className={styles.introducingText}>
            Тому запрошую тебе до нашого{" "}
            <span className={`${styles.highlightedText} ${styles.reversed}`}>
              жiночого очного кола!
            </span>
            <span
              className={`${styles.imageDecorationLine} ${styles.reversed}`}
            ></span>
          </p>
        </div>
      </div>
    </section>
  );
};
