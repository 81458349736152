import slideCard1 from "./images/slideCard1.webp";
import slideCard2 from "./images/slideCard2.webp";
import slideCard3 from "./images/slideCard3.webp";
import slideCard4 from "./images/slideCard4.webp";
import slideCard5 from "./images/slideCard5.webp";
import slideCard6 from "./images/slideCard6.webp";
import slideCard7 from "./images/slideCard7.webp";
import slideCard8 from "./images/slideCard8.webp";
import slideCard9 from "./images/slideCard9.webp";
import slideCard10 from "./images/slideCard10.webp";
import slideCard11 from "./images/slideCard11.webp";
import slideCard12 from "./images/slideCard12.webp";
import slideCard13 from "./images/slideCard13.webp";
import slideCard14 from "./images/slideCard14.webp";
import slideCard15 from "./images/slideCard15.webp";
import slideCard16 from "./images/slideCard16.webp";

export const imagesData = {
  slideCard1: `${slideCard1}`,
  slideCard2: `${slideCard2}`,
  slideCard3: `${slideCard3}`,
  slideCard4: `${slideCard4}`,
  slideCard5: `${slideCard5}`,
  slideCard6: `${slideCard6}`,
  slideCard7: `${slideCard7}`,
  slideCard8: `${slideCard8}`,
  slideCard9: `${slideCard9}`,
  slideCard10: `${slideCard10}`,
  slideCard11: `${slideCard11}`,
  slideCard12: `${slideCard12}`,
  slideCard13: `${slideCard13}`,
  slideCard14: `${slideCard14}`,
  slideCard15: `${slideCard15}`,
  slideCard16: `${slideCard16}`,
};
