import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
// CHANGE TO WOMENPLACE http://127.1.2.205:3000 https://www.women.place
axios.defaults.baseURL = "https://www.women.plac";
axios.defaults.withCredentials = true;
const setAuthHeader = (token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
};

// Utility to remove JWT
const clearAuthHeader = () => {
  axios.defaults.headers.common.Authorization = "";
};

export const register = createAsyncThunk(
  "auth/register",
  async (credentials, thunkAPI) => {
    try {
      const res = await axios.post("/auth/register", credentials);

      // After successful registration, add the token to the HTTP header
      console.log(res.data.data);
      setAuthHeader(res.data.data.token);
      await axios.post("userdata/create");
      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const logIn = createAsyncThunk(
  "auth/login",
  async (credentials, thunkAPI) => {
    try {
      const res = await axios.post("/auth/login", credentials);
      // After successful login, add the token to the HTTP header
      setAuthHeader(res.data.data.token);
      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const logOut = createAsyncThunk("auth/logout", async (thunkAPI) => {
  try {
    await axios.post("/auth/logout");
    // After successful login, add the token to the HTTP header
    clearAuthHeader();
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const refreshUser = createAsyncThunk(
  "auth/refresh",
  async (_, thunkAPI) => {
    // Reading the token from the state via getState()
    const state = thunkAPI.getState();
    const persistedToken = state.auth.token;

    if (persistedToken === null) {
      // If there is no token, exit without performing any request
      return thunkAPI.rejectWithValue("Unable to fetch user");
    }

    try {
      // If there is a token, add it to the HTTP header and perform the request
      setAuthHeader(persistedToken);
      const res = await axios.post("/auth/refresh");

      const newToken = res.data.data.accessToken;
      setAuthHeader(newToken); // Установка нового токена
      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getGoogleOAuthUrl = createAsyncThunk(
  "auth/getGoogleOAuthUrl",
  async (_, thunkAPI) => {
    try {
      const res = await axios.get("/auth/get-oauth-url");

      return res.data.data.url; // Вернуть URL для авторизации
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Логин или регистрация через Google OAuth
export const signInOrLoginWithGoogle = createAsyncThunk(
  "auth/loginWithGoogle",
  async (code, thunkAPI) => {
    try {
      console.log("Code received for OAuth:", code);
      const res = await axios.post("/auth/confirm-oauth", { code });

      // Проверяем ответ
      if (!res.data || !res.data.data || !res.data.data.token) {
        throw new Error("Token not found in response.");
      }

      // Сохраняем access token в заголовок
      setAuthHeader(res.data.data.token);

      // Создаем пользователя
      await axios.post("userdata/create");

      return res.data.data; // Возвращаем response (response: {user, session})
    } catch (error) {
      console.error("Error during Google OAuth:", error);
      return thunkAPI.rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const getUserSettings = createAsyncThunk(
  "auth/getUserSettings",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get("auth/user-settings");
      return response.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Можно менять данные через это
export const updateUserSettings = createAsyncThunk(
  "auth/updateUserSettings",
  async (payload, thunkAPI) => {
    try {
      const response = await axios.patch("auth/user-settings", payload);
      return response.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
