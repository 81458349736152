import { BookingModal } from "../BookingModal/BookingModal";
import styles from "./Galarey.module.css";
import imgInText1 from "./imgInText1.webp";
import imgInText2 from "./imgInText2.webp";

// Импорт изображений
// import VIMG1 from "./IMG_1809V.webp";
// import VIMG2 from "./IMG_1804V.webp";
// import VIMG3 from "./IMG_1803V.webp";
// import VIMG4 from "./IMG_1799V.webp";
// import VIMG5 from "./IMG_1798V.webp";
// import VIMG6 from "./IMG_1777V.webp";
// import VIMG7 from "./IMG_1797V.webp";
// import VIMG8 from "./IMG_1773V.webp";
// import HIMG1 from "./IMG_1770H.webp";
// import HIMG2 from "./IMG_1772H.webp";
// import HIMG3 from "./IMG_1800H.webp";
// import HIMG4 from "./IMG_1801H.webp";
// import HIMG5 from "./IMG_1802H.webp";
// import HIMG6 from "./IMG_1807H.webp";
// import HIMG7 from "./IMG_1808H.webp";

// const verticalImages = [VIMG1, VIMG2, VIMG3, VIMG4, VIMG5, VIMG6, VIMG7, VIMG8];
// const horizontalImages = [HIMG1, HIMG2, HIMG3, HIMG4, HIMG5, HIMG6, HIMG7];

export default function Galarey() {
  return (
    <section className={styles.sectionGalarey} id="galarey">
      <h2 className={styles.titleGalarey}>галерея</h2>
      {/* Добавляем два раза тот же список для эффекта бесконечной ленты */}
      {/* <ul className={styles.galareyFirstCollection}>
        {verticalImages.concat(verticalImages).map((img, index) => (
          <li key={`v-${index}`}>
            <img
              src={img}
              alt="galareyIMGV"
              className={styles.galareyIMGV}
              loading="lazy"
              width={200}
              height={340}
            />
          </li>
        ))}
      </ul> */}
      {/* <ul className={styles.galareySecondCollection}>
        {horizontalImages.concat(horizontalImages).map((img, index) => (
          <li key={`h-${index}`}>
            <img
              src={img}
              alt="galareyIMGH"
              className={styles.galareyIMGH}
              loading="lazy"
              width={340}
              height={200}
            />
          </li>
        ))}
      </ul> */}
      <div className="container">
        <h3 className={styles.titleForOpenForm}>
          забронюй місце зараз{" "}
          <img
            className={styles.imgText}
            src={imgInText1}
            alt="img"
            width={110}
            height={50}
          />{" "}
          і потрапляй на зустріч{" "}
          <img
            className={styles.imgText}
            src={imgInText2}
            alt="img"
            width={110}
            height={50}
          />
          за найнижчу ціну!
        </h3>
        <BookingModal />
      </div>
    </section>
  );
}