import styles from "./Settings.module.css";
import sprite from "../../icons.svg";

export default function Settings() {
  return (
    <section className={styles.settingsSection}>
      <div className="container">
        <h2 className={styles.titleSettings}>
          <svg className={styles.titleSettingsSVG} width={20} height={20}>
            <use xlinkHref={`${sprite}#icon-settingsProfile`}></use>
          </svg>
          Налаштування
        </h2>
        <ul className={styles.settingsList}>
          <li>
            <svg className={styles.MyVideoSVG} width={20} height={20}>
              <use xlinkHref={`${sprite}#icon-clientSVG`}></use>
            </svg>
            Загальна інформація
          </li>
          <li>
            <svg className={styles.MyVideoSVG} width={20} height={20}>
              <use xlinkHref={`${sprite}#icon-imgSVG`}></use>
            </svg>
            Вигляд
          </li>
          <li>
            <svg className={styles.MyVideoSVG} width={20} height={20}>
              <use xlinkHref={`${sprite}#icon-phoneSVG`}></use>
            </svg>
            Контакти
          </li>
          <li>
            <svg className={styles.MyVideoSVG} width={20} height={20}>
              <use xlinkHref={`${sprite}#icon-likeSVG`}></use>
            </svg>
            Інтереси
          </li>
          <li>
            <svg className={styles.MyVideoSVG} width={20} height={20}>
              <use xlinkHref={`${sprite}#icon-notificationsProfile`}></use>
            </svg>
            Сповіщення
          </li>
          <li>
            <svg className={styles.MyVideoSVG} width={20} height={20}>
              <use xlinkHref={`${sprite}#icon-lockedSVG`}></use>
            </svg>
            Параметри входу
          </li>
          <li>
            <svg className={styles.MyVideoSVG} width={20} height={20}>
              <use xlinkHref={`${sprite}#icon-binSVG`}></use>
            </svg>
            Видалити профіль
          </li>
        </ul>
      </div>
    </section>
  );
}
