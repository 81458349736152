import style from "./AggreTeez.module.css";
import { ChoiseAggreVideo, ModalPlayVideoFromYouTube } from "../ModulesAggre";
import { Swiper, SwiperSlide } from "swiper/react";
import { Scrollbar, Navigation, Virtual, Mousewheel } from "swiper/modules";
import { useState, useEffect } from "react";
import "swiper/css";
import "swiper/css/pagination";
import sprite from "../../../icons.svg";
import AdamovaIMGVideo from "./AdamovaIMGVideo.webp";
import BaevaIMGVideo from "./BaevaIMGVideo.webp";
import DiveevaIMGVideo from "./DiveevaIMGVideo.webp";
import IshkovaIMGVideo from "./IshkovaIMGVideo.webp";
import LevchenkoIMGVideo from "./LevchenkoIMGVideo.webp";
import MarchekIMGVideo from "./MarchekIMGVideo.webp";
import MatushevskaIMGVideo from "./MatushevskaIMGVideo.webp";
import NazarenkoIMGVideo from "./NazarenkoIMGVideo.webp";
import PavluchokIMGVideo from "./PavluchokIMGVideo.webp";
import PrundikovaIMGVideo from "./PrundikovaIMGVideo.webp";
import ZimovinIMGVideo from "./ZimovinIMGVideo.webp";

export default function AggreTeez() {
  const [modalPrizeIsOpen, setModalPrizeOpen] = useState(false);
  const [currentVideoUrl, setCurrentVideoUrl] = useState(null);
  const [slidesPerView, setSlidesPerView] = useState(getSlidesPerView());

  useEffect(() => {
    function handleResize() {
      setSlidesPerView(getSlidesPerView());
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  function getSlidesPerView() {
    return window.innerWidth >= 455 ? 4 : 1;
  }

  const speakersOnTeezIMG = [
    {
      imgOnVideo: NazarenkoIMGVideo,
      modalVideo:
        "https://www.youtube.com/embed/yZuJBdFRnSQ?controls=0&si=DkZx4LTZXH2aAu5P&autoplay=1&rel=0",
    },
    {
      imgOnVideo: BaevaIMGVideo,
      modalVideo:
        "https://www.youtube.com/embed/KELaG5jQBxk?controls=0&si=7ndax6RPrEI9RDK8&autoplay=1&rel=0",
    },
    {
      imgOnVideo: MatushevskaIMGVideo,
      modalVideo:
        "https://www.youtube.com/embed/yaKa0t3cWRs?controls=0&si=EwdSY8qPWfo7HjNa&autoplay=1&rel=0",
    },
    {
      imgOnVideo: IshkovaIMGVideo,
      modalVideo:
        "https://www.youtube.com/embed/76twHQ1VA1E?controls=0&si=E4g1H2KP0bgjAx7X&autoplay=1&rel=0",
    },
    {
      imgOnVideo: PrundikovaIMGVideo,
      modalVideo:
        "https://www.youtube.com/embed/L50FzU6BYKU?controls=0&si=3k8k_Wtb06rl-Nrd&autoplay=1&rel=0",
    },
    {
      imgOnVideo: MarchekIMGVideo,
      modalVideo:
        "https://www.youtube.com/embed/ybn3WocDANs?controls=0&si=TbjCe6xKbFqb6u5X&autoplay=1&rel=0",
    },
    {
      imgOnVideo: ZimovinIMGVideo,
      modalVideo:
        "https://www.youtube.com/embed/XdFEtaY8NwI?controls=0&si=NP84Vuc-AMPcA7eN&autoplay=1&rel=0",
    },
    {
      imgOnVideo: PavluchokIMGVideo,
      modalVideo:
        "https://www.youtube.com/embed/_9yIrLPh6DU?controls=0&si=33BMIe9vRFw51l-A&autoplay=1&rel=0",
    },
    {
      imgOnVideo: LevchenkoIMGVideo,
      modalVideo:
        "https://www.youtube.com/embed/WhHH8zX6mXE?controls=0&si=sgp2AzEhesj8xm7B&autoplay=1&rel=0",
    },
    {
      imgOnVideo: AdamovaIMGVideo,
      modalVideo:
        "https://www.youtube.com/embed/ygVAwAwZV4c?controls=0&si=zC1ODnfescaKacSY&autoplay=1&rel=0",
    },
    {
      imgOnVideo: DiveevaIMGVideo,
      modalVideo:
        "https://www.youtube.com/embed/gt8n9RNos60?controls=0&si=Fw-aBTK6In1vUjde&autoplay=1&rel=0",
    },
  ];

  return (
    <section className="aggreTeezSection" id="upcomingSection">
      <div className="container">
        <h2 className={style.titleAggreTeez}>/превʼю до лекцій</h2>
        <div className={`${style.mySwiperTeezNavigation}`}>
          <div className={style.swiperButtonPrevTeez}>
            <svg
              onClick={(e) => e.preventDefault()}
              className=""
              width={60}
              height={60}
              fill="white"
            >
              <use xlinkHref={`${sprite}#arrow-right`}></use>
            </svg>
          </div>
          <div className={style.swiperButtonNextTeez}>
            <svg
              onClick={(e) => e.preventDefault()}
              className=""
              width={60}
              height={60}
              fill="white"
            >
              <use xlinkHref={`${sprite}#arrow-left`}></use>
            </svg>
          </div>
        </div>
        <Swiper
          spaceBetween={20}
          slidesPerView={slidesPerView}
          mousewheel={true}
          scrollbar={{ draggable: true }}
          navigation={{
            prevEl: `.${style.swiperButtonPrevTeez}`,
            nextEl: `.${style.swiperButtonNextTeez}`,
          }}
          modules={[Scrollbar, Navigation, Virtual, Mousewheel]}
          className={style.mySwiperTeez}
        >
          {speakersOnTeezIMG.map((IMGFromYT, index) => (
            <SwiperSlide
              key={index}
              className={style.swiperSlidePlayVideo}
              onClick={() => setCurrentVideoUrl(IMGFromYT.modalVideo)}
            >
              <img
                className={style.aggreSpeakerOnVideo}
                src={IMGFromYT.imgOnVideo}
                alt={`Speaker ${index}`}
              />
              <svg
                className={style.playVideoBtn}
                width={60}
                height={60}
                fill="white"
              >
                <use xlinkHref={`${sprite}#icon-play`}></use>
              </svg>
            </SwiperSlide>
          ))}
        </Swiper>
        <button
          className={`${style.redBtnAggreTeez} redBtn`}
          onClick={() => setModalPrizeOpen(true)}
        >
          Придбати записи
        </button>
      </div>
      <ChoiseAggreVideo
        isOpen={modalPrizeIsOpen}
        onClose={() => setModalPrizeOpen(false)}
      />
      <ModalPlayVideoFromYouTube
        isOpen={currentVideoUrl !== null}
        onClose={() => setCurrentVideoUrl(null)}
        videoURL={currentVideoUrl}
      />
    </section>
  );
}
